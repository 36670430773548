// middle
#middle {}

// На сколько уменьшить высоту
$index-pic-height-1100: 40px;
$index-pic-height-820: 100px;
$index-pic-height-small: 150px;
$index-pic-height: 340px; // по высоте баннера

#index-pic {
    background: url('/images/_design/header-main.jpg');
    background-repeat: no-repeat;

    width: 100%;
    height: $index-pic-height;

    // Блок контента в баннере на главной

    /*
    .site-index {
        box-sizing: content-box;
        float: right;
        padding: 20px 25% 0 0;
        width: 32%;

        h1 {
            font-family: "Times New Roman",times,serif;
            font-size: 40px;
            font-style: oblique;
            line-height: 1.2;
        }

        p {
            font-size: 15px;
            line-height: 1.2;
        }
    }

    @include breakpoint(1100px down) {
        height: 422px;
        background-size: auto 422px;

        .site-index {
            padding: 22px 10% 0 0;
            width: 40%;

            h1 {
                font-size: 30px;
            }

            p {
                font-size: 14px;
                margin-bottom: 0.7rem;
            }
        }
    }

    @include breakpoint(820px down) {
        height: 350px;
        background-size: auto 350px;

        .site-index {
            padding: 12px 5px 0 0;
            width: 48%;

            h1 {
                margin-bottom: 0;
            }
            p {
                line-height: 1.1;
            }
        }
    }

    @include breakpoint(small only) {
        height: 200px;
        background-size: auto 200px;

        .site-index {
            display: none !important;
        }
    }
    */

    // Баннер анонсов
    .banner-anons-container {
        float: right;
        border-bottom: 1px dashed red;
        @include wg-main_banner-anons;

        // div
        .orbit { 
            // nav
            .orbit-bullets {
                margin-top: -30px;
                margin-bottom: 0;
                padding-bottom: 4px;
                background: rgba(white, 0.5);
            }
        }
    }

    @include breakpoint(1100px down) {
        $slide-height: 340px - $index-pic-height-1100;
        $img-height: 300px - $index-pic-height-1100;

        height: $slide-height;
        background-size: auto $slide-height;
        .banner-anons-container {
            // div
            .orbit { 
                // ul
                .orbit-container {  
                    max-height: $slide-height;

                    .orbit-slide {
                        // Ссылка-обретка слайда
                        >a {}
                        // Конейнер содержимого слайда
                        .container {
                            height: $slide-height;

                            .anons {

                                >div { }

                                .date {}
                                .title {}
                                .details {
                                    width: 85%;
                                    >* {
                                    }

                                }
                            }

                            .image {
                                line-height: $slide-height;
                                .img-container {
                                    img{
                                        max-height: $img-height;
                                    }
                                }
                            }
                        }
                    }


                }

            }
        }
    }

    @include breakpoint(820px down) {
        $slide-height: 340px - $index-pic-height-820;
        $img-height: 300px - $index-pic-height-820;

        height: $slide-height;
        background-size: auto $slide-height;
        .banner-anons-container {
            // div
            .orbit { 
                // ul
                .orbit-container {  
                    max-height: $slide-height;

                    .orbit-slide {
                        // Ссылка-обретка слайда
                        >a {}
                        // Конейнер содержимого слайда
                        .container {
                            height: $slide-height;

                            .anons {
                                padding-top: 1.2rem;
                                >div { }

                                .date {
                                    font-size: 1.1rem;
                                }
                                .title {
                                    width: 90%;
                                    font-size: 1.3rem;
                                    font-weight: bold;
                                }
                                .details {
                                    width: 90%;
                                    font-size: 0.85rem;
                                    >* {
                                    }

                                }
                            }

                            .image {
                                line-height: $slide-height;
                                .img-container {
                                    img{
                                        max-height: $img-height;
                                    }
                                }
                            }
                        }
                    }


                }
            }
        }
    }

    @include breakpoint(small only) {
        $slide-height: 340px - $index-pic-height-small;
        $img-height: 300px - $index-pic-height-small;

        height: $slide-height;
        background-size: auto $slide-height;
        .banner-anons-container {
            // div
            .orbit { 
                // ul
                .orbit-container {  
                    max-height: $slide-height;

                    .orbit-slide {
                        // Ссылка-обретка слайда
                        >a {}
                        // Конейнер содержимого слайда
                        .container {
                            height: $slide-height;

                            background: linear-gradient(
                                to left, 
                                rgba($white, 0.0) 0%,
                                rgba($white, 0.75) 40%,
                                rgba($white, 1.0) 100%
                                );

                            .anons {
                                padding-top: 0;
                                >div { }

                                .date {
                                    display: none;
                                    font-size: 0.95rem;
                                    font-weight: bold;
                                }
                                .title {
                                    font-size: 1.0rem;
                                }
                            }

                            .image {
                                line-height: $slide-height;
                                .img-container {
                                    img{
                                        max-height: $img-height;
                                    }
                                }
                            }
                        }
                    }
                }

                // nav
                .orbit-bullets {
                    display: none;
                }
            }
        }
    }
}

#site-pic {
    background: url('/images/_design/header-site.jpg');
    background-repeat: no-repeat;

    width: 100%;
    height: 248px;

    // Баннер анонсов
    .banner-anons-container {
        float: left;
        border-bottom: 1px dashed red;
        @include wg-main_banner-anons;

        $slide-height: 248px;
        $img-height: 248px;
        // div
        .orbit { 
            // ul
            .orbit-container {  
                max-height: $slide-height;

                .orbit-slide {
                    // Ссылка-обретка слайда
                    >a {}
                    // Конейнер содержимого слайда
                    .container {
                        height: $slide-height;

                        .anons {

                            >div { }

                            .date {}
                            .title {}
                            .details {

                                >* {
                                }

                            }
                        }

                        .image {
                            line-height: $slide-height;
                            .img-container {
                                img{
                                    max-height: $img-height;
                                }
                            }
                        }
                    }
                }


            }

            // nav
            .orbit-bullets {
                margin-top: -30px;
                margin-bottom: 0;
                padding-bottom: 4px;
                background: linear-gradient(to right, transparent 0%, rgba(white, 0.5) 70% ,rgba(white, 0.5) 100%);
            }
        }
    }


    @include breakpoint(1100px down) {
        .banner-anons-container {
            // div
            .orbit { 
                // ul
                .orbit-container {  

                    .orbit-slide {
                        // Ссылка-обретка слайда
                        >a {}
                        // Конейнер содержимого слайда
                        .container {
                            .anons {
                                .details {
                                    width: 80%;
                                }
                            }
                        }
                    }


                }

            }
        }
    }



    @include breakpoint(820px down) {
        .banner-anons-container {
            // div
            .orbit { 
                // ul
                .orbit-container {  

                    .orbit-slide {
                        // Ссылка-обретка слайда
                        >a {}
                        // Конейнер содержимого слайда
                        .container {
                            .anons {
                                padding-top: 1.2rem;
                                >div { }

                                .date {
                                    font-size: 1.1rem;
                                }
                                .title {
                                    width: 90%;
                                    font-size: 1.3rem;
                                    font-weight: bold;
                                }
                                .details {
                                    width: 90%;
                                    font-size: 0.85rem;
                                    >* {
                                    }

                                }
                            }
                        }
                    }


                }

            }
        }
    }



    @include breakpoint(small only) {
        height: 200px;
        background-size: auto 200px;

        .banner-anons-container {
            $slide-height: 200px;
            $img-height: 150px;
            // div
            .orbit { 
                // ul
                .orbit-container {  
                    max-height: $slide-height;

                    .orbit-slide {
                        // Ссылка-обретка слайда
                        >a {}
                        // Конейнер содержимого слайда
                        .container {
                            height: $slide-height;

                            background: linear-gradient(
                                to left, 
                                rgba($white, 0.0) 0%,
                                rgba($white, 0.75) 40%,
                                rgba($white, 1.0) 100%
                                );

                            .anons {
                                padding-top: 0;
                                >div { }

                                .date {
                                    display: none;
                                    font-size: 0.95rem;
                                    font-weight: bold;
                                }
                                .title {
                                    font-size: 1.0rem;
                                }
                            }

                            .image {
                                line-height: $slide-height;
                                .img-container {
                                    img{
                                        max-height: $img-height;
                                    }
                                }
                            }
                        }
                    }
                }

            }

            // nav
            .orbit-bullets {
                display: none;
            }
        }
    }

}

// Global styles

html, body {
    //padding-bottom: rem-calc(68);
}


#PageWrapper {
    margin: 0 auto;

    // он же .off-canvas-content
    #mainContainer {  
        // псевдоконтейнер page.top +++++++++++
        // #middle
        .middle {}
        .breadcrumbs-container {}

        // он же #maincontent
        .main-content {
            // row collapse
            #columns-top {}
            // row collapse
            #columns {
                .sidebar-column {
                    // name="div.sidebar.main"
                    .sidebar.sidebar-main{}
                    // name="div.sidebar.additional"
                    .sidebar.sidebar-additional{}
                }
                .main-column {
                    // Заголовок контента
                    #content-top {
                        // h1
                        .page-title-wrapper{}
                        // Сообщения
                        .page.messages{}
                    }
                    // Псевдоконтейнер content++++++++
                    //+++++++++++++++++++++++++++++++++
                    .cms-page-view.content-wrapper {}
                    .category-view.top.content-wrapper {}
                    .category-view.bottom.content-wrapper {}
                    // Каталог ********************
                    // Категории
                    .toolbar.toolbar-products {}
                    .products.wrapper.grid.products-grid {}


                    // Псевдоконтейнер content.aside+++++++++++++++++++++++
                    //+++++++++++++++++++++++++++++++++

                    // Псевдоконтейнер content.bottom++++++++++++++++++++++++++
                    //+++++++++++++++++++++++++++++++++



                }
            } // .columns

            .page-bottom {
                .row.column.content-wrapper {}
            }

            footer.page-footer {

            }

        } // .main-content
    } // #mainContainer
} // #PageWrapper

#mainContainer {  
    background: (
        url('/media/images/_design/bg-hiero.jpg')
        /*linear-gradient(to bottom, $bg-header-top-bar-1 0%, $bg-header-top-bar-2 100%)*/

        );
    background-repeat:(repeat /*repeat-x*/);
    background-position:(center center /*0 0*/);
    background-size:(auto 500px);
    // полоска снизу
    //border-bottom: 3px solid $bg-gray;
}

// Колонки
#columns.row {
    .sidebar-column {
        padding-top: 1rem;
        padding-left: 0;
        padding-bottom: 1rem
    }
    .main-column {
        padding-top: 1rem;
        padding-right: 0;
    }
}



// Крошки
.breadcrumbs-container {
    padding-top: 0.2rem;
    .breadcrumbs{
        li{

            &:after{
                color: $fg-secondary;
            }
        }
    }
}

// Заголовок страницы - page.main.title
.page-title-wrapper {
    // h1
    .page-title {
        span.base {}
    }
}

// Сообщения
//.page.messages{   
.message, .callout {
    font-family: $f-Gabriela;
    font-size: 0.9rem;
    color: $fg-gray;

    border: 0 none;
    border-radius: 6px;
    padding: 0.5rem 1rem;
    &.success {
        background-color: $fg-green-pastel;
    }
    &.info, &.notice {
        background-color: $fg-yellow-pastel;
    }
    &.error {
        background-color: $fg-red-pastel;
    }
}
//}



// Пейджер
// без контейнера, общий
@include wg-foundation-pagination;

.pager-container {
    &.top {
        margin-top: 1.5rem;
    }
    &.bottom {
        margin-bottom: 1rem;
    }
    .pagination {
        margin-bottom: 0.5rem;
    }
}




//  ++++++++++++++++++ Главная +++++++++++++++++++++++

.cms-index-index {
    #columns.row .main-column {
        padding-top: 0;
        padding-left: 0;
    }

    .page.messages {
        display: none;
    }

    // Новые товары
    .block-new-products {}
}


// Слайдер
.slider-container {
    background: ( 
        url('/media/images/_design/slider/bg-slider.jpg')
        /*linear-gradient(to bottom, $bg-header-top-bar-1 0%, $bg-header-top-bar-2 100%)*/
        );
    background-repeat:(repeat /*repeat-x*/);
    background-position:(center center /*0 0*/);
    background-size:(auto 250px);
    background-color: white;

    border-radius: 0 0 5rem 5rem;

    border: 1px solid lighten($fg-input-border, 2);
    border-top: 0;
    //border-bottom-style: double;
    border-radius: 0 0 4rem 4rem;


    //width: 1200px;
    //height: 520px;
    padding-bottom: 1rem;
    //background: gray;
    text-align: center;
}


.slider-wrapper { 
    max-width: 900px;
    display: inline-block;
    //width: 1200px;
    //height: 500px;
    background-color: white;
    border: 10px solid $fg-vio-pastel3;
    border-top: 0;
    border-bottom-style: double;
    border-radius: 0 0 4rem 4rem;
    overflow: hidden; 

    .orbit-container {
        height: auto;
    }
}

@include breakpoint (1200px down) {
    .slider-container { 
        border-radius: 0;
    }
}

@include breakpoint (900px down) {
    .slider-container { 
        border: 0;
        background: none;
    }

    .slider-wrapper { 
        border-radius: 0;
        border-bottom-style: solid;
    }
}


// Текст на главной ++++++++++++++++++++++++++++++
.text-index-container.content-wrapper {
    margin-top: 2.5rem;
    margin-bottom: 1rem;

    h1, h2, h3, h4 {
        text-align: center;
    }
}


// Сайтмэп ++++++++++++++++++++++++++++++++++++++++++++

.Sitemap {
    margin-bottom: 1rem;

    &-Heading {
        margin-top: 1.5rem;
    }

    &-Items {}
    &-Item {
        text-align: left;
    }
    &-Action {
        text-decoration: none !important;
    }
}






// Header

$sh-top: 0 -10px 10px -10px hsb(0, 0, 0, 0.4);
$sh-bottom: 0 10px 10px -10px hsb(0, 0, 0, 0.8);

$height_panel-header: 28px;
$height_header-content: 100px;
$height_menu: 34px;
$height_header: $height_panel-header + $height_header-content + $height_menu;

$height_small-header: 40px;
$height_header-content_small: 50px;
$height_header_small: $height_small-header + $height_header-content_small;

$fg-header: $fg-vio-dark;

.page-header {
    //opacity:0.5;
    height: $height_header;
    position: relative;

    // Sticky
    .sticky-top{   
        background: (
            url('/media/images/_design/bg-compass.jpg')
            /*linear-gradient(to bottom, $bg-header-top-bar-1 0%, $bg-header-top-bar-2 100%)*/

            );
        background-repeat:(no-repeat /*repeat-x*/);
        background-position:(center center /*0 0*/);
        background-size:(auto 700px);
        // полоска снизу
        //border-bottom: 3px solid $bg-gray;


        //height: $height_header;
        z-index: 10;   
        background-color: white;
        box-shadow: 0 -3px 8px;


    }

    // Панель в шапке
    .panel.wrapper {
        background: $bg-header-panel-rgba;
        border-bottom: 2px solid $fg-vio-pastel3;

        // Общий стиль меню в топ-баре
        .menu {
            li {               
                * {
                    color: $fg-header;
                    i {color: $fg-icon-def;}
                }
                >a, >span{
                    padding: 0 0.5rem;
                    line-height: $height_panel-header;
                    font-size: 0.75rem;

                    @include breakpoint (medium up) {
                        font-size: 0.85rem;
                    }

                }
                &.is-dropdown-submenu-parent >a {
                    padding-right: 1.5rem;
                }
                &:not(.greet) {
                    transition: background-color 0.2s ease-in 0.0s;
                }
            }

        }
        // Верхняя строка
        .dropdown.menu {
            background: transparent;
            > li {
                * { }
                >a i {
                    vertical-align: inherit;
                    &.af-yingyang,&.af-yingyang-scetch {
                        vertical-align: middle;
                    } 
                }
                &:not(.greet) {
                    &:hover {
                        background-color: lighten($bg-header-panel, 25);
                    }
                }
            }
        }
        // Вложенные меню
        @include wg-foundation_menu-submenu;
        .submenu {
            min-width: 200px;
        }

        .top-bar {
            background: transparent;
            height: $height_panel-header;
            overflow: visible; // перекрытие меню

            // Меню регистрации клиента
            .top-bar-left, .top-bar-right {
                width: auto;
            }
        }

        // .top-bar-right
        .panel.header {

            .header.links.menu.dropdown {
                // Сравнение товаров
                li.link.compare {
                    @include breakpoint(760px down) { 
                        display: none;
                    }
                }

                // Добро пожаловать
                li.greet.welcome {
                    @include breakpoint(860px down) { 
                        display: none;
                    }
                    >span {
                        color: $fg-blue3;
                        //text-shadow: 0 0 6px;
                    }
                }

                // Кнопка войти 
                >li.authorization-link a{
                    i{
                        color: $fg-icon-green;
                    }
                }

                // Выводится после залогинивания
                li.customer-welcome { 
                    // Имя пользователя с иконкой
                    >a {
                        padding-right: 1.3rem; // Отступ от стрелки
                        .customer-name {                              
                            > span {
                                color: $fg-foury;
                                font-weight: bold;

                                // Ограничение по ширине
                                display: block;
                                float: left;
                                height: $height_panel-header;
                                max-width: 200px;
                                overflow: hidden;
                                text-align: right;
                                word-break: break-all;
                                padding-right: 0.2rem;
                            }
                        }
                        i {
                            font-size: 1.2rem;
                            margin: 0 0 0 0.2rem;
                        }
                    }

                    // После залогинивания скрываем кнопку войти
                    + .authorization-link {
                        display: none;
                    }

                    // Подменю
                    .submenu {
                        // Мои пожелания
                        li.wishlist {
                            i {color: $fg-icon-red;}
                        }
                        // Мой аккаунт
                        li.account-link {
                            i {
                                color: $fg-icon-green;
                                vertical-align: middle;
                                margin-top: -3px;
                            }
                        }
                        // Выход
                        li.exit {
                            i {margin-top: -3px;}
                        }

                        //display: block;
                    }
                }
            }
        }
    }   

    // Меню - в menu.scss
    .sections.nav-sections {}
}





$search-color: $fg-vio-dark2;
$search-input-padding: 1rem;
@mixin block-search {
    .block-search {
        display: table-cell;
        vertical-align: middle;
        //padding-left: $search-input-padding;
        //width: 250px;

        // Форма
        .field.search {               
            .control {
                margin-bottom: 0;
                .input-text {
                    padding-left: $search-input-padding;
                    border-radius: 20px 0 0 20px;
                    color: $search-color;

                    // При поиске прячем закругление
                    &[aria-expanded="true"] {
                        border-radius: 20px 0 0 0;
                    }


                    &:placeholder {
                        color: $fg-vio;
                    }
                }
                .actions.input-group-button {
                    width: auto;
                    .button {
                        float: left;
                        border-radius: 0;

                        background: white;
                        color: $fg-icon-green; //$fg-red-deep2;
                        border: 1px solid $fg-input-border;
                        border-left: 0;

                        font-size: 1.5rem;
                        padding: 0 0.8rem;

                        //Кнопка поиска
                        &.search {
                            border-radius: 0 20px 20px 0;
                        }
                        //Кнопка очистить поиск
                        &.clear-search {
                            padding: 0 0.3rem;
                            @include link-vio;
                        }

                        transition: $trans-color;
                        &:hover {
                            //color: lighten($fg-red-deep2, 5);
                            color: lighten($fg-icon-green, 5);
                        }

                        &[disabled]{
                            opacity: 1;
                            //color: rgba($fg-red-deep2, 0.25);
                            color: rgba($fg-icon-green, 0.25);
                        }

                        // Для одиночной кнопки
                        padding-left: 0.6rem;
                    }
                    // Вертикальное выравнивание кнопки расширенного поиска
                    a i {
                        line-height: 2.5rem;
                    }
                }



                .search-autocomplete {
                    display: none;
                    //margin-top: -16px;
                    overflow: hidden;
                    position: absolute;
                    z-index: 3;
                    color: $search-color;

                    ul {
                        list-style: outside none none;
                        line-height: 1;
                        margin: 0;
                        padding: 0;
                        border-radius: 20px 0 20px 4px;
                        overflow: hidden;                            

                        &:not(:empty) {                                
                            background: white none repeat scroll 0 0;
                            border: $input-border-focus;
                            border-top: 0;
                        }
                        li:hover, li.selected {
                            background: $fg-vio-pastel2 none repeat scroll 0 0;
                            //color: white;
                        }
                        li:first-child {
                            border-top: medium none;
                        }
                        li {
                            cursor: pointer;
                            margin: 0;
                            padding: 5px 40px 5px $search-input-padding;
                            position: relative;
                            text-align: left;

                            .amount {
                                color: $fg-red-pale;
                                position: absolute;
                                right: 7px;
                                top: 5px;
                            }
                        }
                    }
                }
            }
        }
    }
}








// Основной header
.page-header {
    .header-content {     
        padding: 0;
        //border-bottom: 1px dotted;
        height: $height_header-content;

        >.top-bar.row {
            overflow: visible;// перекрытие меню
            height: 100%;
            background: transparent;

            padding: 0 0.5rem;
        }

        // Середина хедера
        // ++++++++++++++++++++++++
        .top-bar-middle {

            // Старое лого
            // width: 400px;

            // Новое лого
            width: 230px;
            //padding-top: 1.2rem;

            text-align: center;

            @include breakpoint(950px down) { 
                // Для верстки со скрытым поиском
                //float: left;
                //margin-left: 0.5rem;
            }

            @include breakpoint(840px down) { 
                display: none;
            }


        }
        // Лого
        .logo {
            position: relative;
            float: left;
        }

        // Лево хедера
        // ++++++++++++++++++++++++++++++++
        .top-bar-left {
            display: table; height: 100%;

            @include breakpoint(850px down) { 
                //display: none;
            }

            @include breakpoint (small down) {
                // для верстки без логотипа и с поиском
                width: 100%;
            }
        }        

        // Поиск
        @include block-search;

        // Инпут поиска алголии на маленьком экране
        @include breakpoint (small down){
            #algolia-searchbox {
                display: flex;

                &.isOpen action.clear-search.button{
                    // прячем кнопку поиска при открытом поиске
                    display:none;
                }
            }

            // строка поиска
            .algolia-search-input {
                width: 100%;
                max-width: none;
                font-size: 1.5rem;
                height: 3rem;
            }

            #algolia-glass .action.search.button, #algolia-glass .action.clear-search.button {
                height: 3rem;
            }

        }

        // Поиск algolia
        #algolia-autocomplete-container {
            .aa-dropdown-menu {
                box-shadow: $bs-menu;          
                border: 1px solid $fg-input-border;
                border-radius: 0 8px 8px 8px;
                padding-bottom: 3rem;
                overflow: hidden;

                @include breakpoint (995px down){
                    .thumb {
                        //display: none;
                    }
                }

                .info {
                    font-family: $f-Gabriela;

                    .algoliasearch-autocomplete-price {
                        font-family: $f-OpenSansCon;
                        font-size: 1.1rem;
                        color: $fg-input;

                        i {
                            font-size: 0.8rem;
                        }
                    }

                    @include breakpoint (995px down){
                        margin-left: 0;
                    }
                }
            }
        }



        // Право хедера
        // ++++++++++++++++++++++++++++++++
        .top-bar-right {
            display: table; height: 100%;
            width: auto;

            @include breakpoint (small down) {
                // для верстки без логотипа и с поиском
                width: auto;
            }
        }   

        // Сумма корзины
        .header-subtotal {
            display: table-cell;
            vertical-align: middle;

            // 2022-04-19
            // Контейнеры телефонов
            .phone-one {
                display: flex;
            }
            .phone-two {
                display: flex;
                padding-left: 0.8rem;
                
                font-family: $f-Marmelad;
                color: $fg-foury; //$fg-fivy;
                font-size: 1.2rem;
                
                a {
                    color: inherit;
                }
            }

            // Контейнер суммы и кнопки в корзину
            .subtotal-cart-wrapper {
                float: right;
            }

            // Сумма  
            .subtotal-to-checkout {
                border-bottom: 0;
                float: left;
                display: block;
                background: white;
            }

            .subtotal {
                font-family: $f-OpenSansCon;
                font-size: 1.5rem;
                color: $fg-vio-dark;
                .price span {
                    font-size: 1.2rem; // валюта
                }
                .price i {
                    font-size: 0.9rem; // валюта иконкой 2020-03-13
                }
            }
            // Кнопка в корзину 
            .action.viewcart {
                display: block;
                font-family: $f-OpenSansCon;
                font-size: 2rem;
                margin-bottom: 0;
                //border-radius: 30px 0 0 30px;
                padding: 0.0em 0.1em 0.0em 0.2em;
                font-weight: normal;

                @include btn-vio;
                border-right: 0;

                height: 50px;
                display: table-cell; vertical-align: middle;             
            }

            .phone {
                float: left;

                div {
                    height: 50px;
                    display: table-cell; vertical-align: middle;

                    padding-right: 0.2em;
                    padding-left: 0.4em;
                    border: 6px solid $fg-vio-pastel2; 
                    border-top-style: double;

                    // Сплошная граница
                    border: 1px solid $fg-vio-pastel2; 
                    border-top-style: solid;


                    border-right: 0;
                    border-radius: 30px 0 0 30px;

                    font-family: $f-Marmelad;
                    color: $fg-foury; //$fg-fivy;
                    font-size: 1.7rem;

                    background: white;

                    // кликабельный телефон
                    a {
                        line-height: inherit;
                        color: inherit;
                        text-decoration: none;
                        background-color: inherit;
                        cursor: default; 

                        &:hover, &:focus {
                            color: inherit;
                        }
                    }

                }
                // Кнопка заказать звонок
                .order-call {
                    display: table-cell;
                    vertical-align: middle;
                    padding: 0 0.5rem;
                    font-size: 1.5rem;

                    //@include icon-edit;
                    color: $fg-tertiary;

                    border: 6px solid $fg-vio-pastel2; 
                    border-top-style: double;

                    // Сплошная граница
                    border: 1px solid $fg-vio-pastel2; 
                    border-top-style: solid;


                    background: white;
                    border-right: 0;

                    border-left: 1px solid $fg-vio-pastel2;
                }
            }


            // Корзина
            .minicart-wrapper {
                display: inline-block;
                vertical-align: middle;
                position: relative; // для меню - absolute

                .action.showcart {
                    // Иконка корзины
                    cursor: pointer;
                    display: table-cell;
                    vertical-align: middle;
                    position: relative; // для количества товаров - absolute
                    text-decoration: none;

                    padding: 0 0.5rem;
                    font-size: 1.5rem;

                    @include icon-edit;

                    @include btn-vio;
                    border-radius: 0 30px 30px 0;
                    //border-right-style: double;
                    border-right-width: 6px;

                    // Сплошная граница
                    border-right-width: 1px;

                    background: white;
                    padding-right: 1rem;
                    height: 50px;

                    /*&:before {
                        //@include fa-icon(); 
                        //content: $fa-var-shopping-basket;
                        content: "";

                        background: url("/media/images/_design/stones.png") no-repeat 0 0 / 100px 100px;
                        display: table-cell;
                        width: 30px;
                        height: 30px;

                        font-size: 30px;
                        line-height: 30px;

                        margin: 0;
                        //overflow: hidden;
                        text-align: center;
                        vertical-align: middle;
                    }*/

                    /*transition: $trans-color;
                    &:hover {
                        color: lighten($fg-red-deep2, 5);
                    }*/

                    // Количество
                    .counter.qty {
                        display: inline-block;

                        background: $fg-secondary; // $fg-fivy; //$fg-tertiary;
                        border-radius: 30px;
                        color: white;
                        font-family: $f-Gabriela;
                        font-size: 1.0rem;

                        height: 20px; width: 20px;
                        line-height: 1.2;
                        //margin: 3px 0 0;
                        text-align: center;

                        position: absolute;
                        top: 1px; left: 12px;
                        font-weight: normal;


                        // Пустая корзина
                        &.empty {
                            display: none;
                        }
                    }
                }
            } //.minicart-wrapper

            // Линк меню корзины
            .action.showcart {
                border: 0; // из-за .has-tip
            }

        } // .header-subtotal

    }

}


// Корзина - меню
.minicart-wrapper {   
    .block-minicart {
        // Стрелка
        &:after {
            content: "";
            display: block;
            height: 0;
            position: absolute;
            width: 0;
            border-color: transparent transparent $fg-secondary;
            border-style: solid;
            border-width: 14px;
            z-index: 99;
            right: 14px;
            top: -28px;
        }

        position: absolute;
        right: 10px;
        width: 350px;
        padding-top: 1rem;
        margin-top: 10px; // для стрелки


        background: white;
        border-radius: 0.8rem;
        border: 0.5rem double $fg-secondary;
        box-shadow: $bs-menu; //0 3px 3px rgba(0, 0, 0, 0.15);
        display: none; // Скрыть при открытии
        min-width: 100%;
        top: 100%;
        z-index: 100;

        // Заголовок
        .block-title {
            display: none;
        }

        // Контейнер
        .block-content {
            // Лейбл по умолчанию
            .label {
                background: transparent;
                color: black;
            }


        }    

    } // .block-minicart

} // .minicart-wrapper


@mixin product-qty-input {
    display: inline-block;
    border-radius: 4px;
    width: 40px;
    margin: 0;
    font-family: $f-Gabriela;
    color: $fg-vio-dark;

    -moz-appearance:textfield;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
}




// Листинг товаров в миникарт и списке желаний в сайдбаре
@mixin minicart-product-item {
    li.product-item {
        &:not(:last-child) {
            border-bottom: 2px solid $fg-blue-deep2;  
        }
        padding: 0.5rem;

        >.product {
            display: table;
            width: 100%;
            // Картинка           
            .product-item-photo{
                float: left;  
                text-align: left;
                .product-image-photo {
                    margin-right: 0.5rem;
                    max-width: 85px;
                }
            }
            // Описание
            .product-item-details{
                //padding-left: 85px; 
                display: table-cell;
                vertical-align: middle;
                line-height: 1.1;
                text-align: right;
                // Название
                .product-item-name{
                    display: block;
                    margin-bottom: 0.3rem;
                    a {
                        font-family: $f-Marmelad;
                        font-weight: normal;
                        font-size: 0.9rem;
                        color: $fg-blue3;     
                        transition: $trans-color;

                        &:hover {
                            color: lighten($fg-blue3, 10);
                        }
                    }
                }
                // Цена кол-во
                .product-item-pricing{
                    display: inline-block;   
                    vertical-align: middle;
                    >* {display: inline-block;}

                    .price-container {
                        font-family: $f-OpenSansCon;
                        font-size: 1.2rem;
                        color: $fg-vio-dark;

                        .price {
                            span {
                                font-size: 0.7em;
                            }
                            i {
                                font-size: 0.7rem;
                            }
                        }
                    }
                    // Кол-во
                    .details-qty {
                        min-width: 70px;
                        text-align: left;

                        .label{
                            font-size: 0.75rem;
                            display: none;
                        }
                        input {                                  
                            @include product-qty-input;
                        }
                    }

                    // Кнопка обновить кол-во
                    .update-cart-item {
                        margin-left: 0.5rem;
                        cursor: pointer;
                        &:before {
                            @include fa-icon(); 
                            content: $fa-var-refresh;

                            transition: $trans-color;
                            color: $fg-blue-deep2;
                            font-size: 20px;
                            line-height: 20px;
                        }

                        &:hover:before {
                            color: darken($fg-blue-deep2, 5);
                        }

                        span{
                            display: none;
                        }
                    }

                }
                // Кнопки
                .product.actions {
                    display: inline-block;
                    >div{                                   
                        display: inline-block; // Контейнеры иконок
                        vertical-align: middle;
                        margin-left: 0.4rem;
                    }
                    .action{
                        // Иконки
                        &:before {
                            @include fa-icon(); 

                            transition: $trans-color;
                            color: $fg-gray;
                            font-size: 20px;
                            line-height: 20px;
                        }

                        &:hover:before {
                            //color: black;
                        }
                        // Редактирование
                        &.edit {
                            &:before {
                                content: $fa-var-pencil;
                                color: $fg-green-deep2;
                            }
                            &:hover:before {
                                color: darken($fg-green-deep2, 5);
                            }
                        }
                        // Удаление
                        &.delete {
                            &:before {
                                content: $fa-var-trash;
                                color: $fg-red;
                            }
                            &:hover:before {
                                color: darken($fg-red, 5);
                            }
                        }
                        span{
                            display: none;
                        }
                    }
                } // .product.actions

            } // .product-item-details

        } // .product
    } // li
}



.block-minicart .block-content {
    // Кнопка закрытия
    .action.close {
        height: 30px;
        width: 30px;
        position: absolute;
        right: 0;
        top: 0;
        cursor: pointer;


        &:before {
            @include fa-icon(); 
            content: $fa-var-close;

            transition: color 0.2s ease-in 0.0s;
            color: $fg-icon-blue;
            font-size: 20px;
            line-height: 10px;
        }

        &:hover:before {
            color: darken($fg-icon-blue, 5);
        }

        span {
            display: none;
        }
    }

    // Сообщение - корзина пустая
    > .subtitle {
        display: block;
        padding: 0 1rem 1rem;
        text-align: center;
        color: $fg-vio;

    }


    // Кол-во позиций
    .items-total {
        float: left;
        margin: 0 10px;
        .count {
            font-weight: bold;
        }
    }
    // Сумма корзины
    .subtotal {
        margin: 0 10px;
        text-align: right;
        // Подпись
        .label {

        }

        // Сумма
        .amount .price-wrapper:first-child .price {
            font-size: 1.2rem;
            font-weight: bold;
        }            
    }       

    .block-content >.actions{
        text-align: center;

        // Перейти к оформлению заказа
        .primary {             
            button {
                @include button;
                margin-top: 0.5rem;
            }
        }
        // Просмотр и редактирование корзины
        .secondary {}
    }

    .subtitle{
        // Пустая корзина
        &.empty {}
        &:not(.empty){
            display: none;
        }

    }

    // Листинг корзины +++++++++++++++++++++++++++++++++++++++++
    .minicart-items-wrapper {
        // Переполненная корзина
        //&.overflowed {
        overflow-x: hidden;
        overflow-y: scroll;
        margin-top: 1rem;

        &:-webkit-scrollbar {
            width: 0.5rem;
        }
        &:-webkit-scrollbar-track {
            background-color: $fg-secondary;
            border-left: 1px solid $fg-secondary;
        }
        &:-webkit-scrollbar-track-piece {
            background-color: $fg-secondary;
        }
        &:-webkit-scrollbar-thumb:veritcal {
            background-color: $fg-secondary;
        }
        //}
    }

    .minicart-items {
        list-style: outside none none;
        margin: 0;

        @include minicart-product-item;     

    } // .minicart-items


    // Кнопки внизу корзины
    >.actions {
        .secondary {
            margin-top: 0.3rem;
            .action.viewcart {
                display: block;
                text-align: center;
                background: $fg-blue-deep2;
                color: white;
                font-size: 1.5rem;
                border: 0;
                padding: 0.5rem 0;
                transition: $trans-bg-color;              

                &:hover {
                    background: darken($fg-blue-deep2, 5);
                }
            }
        }
    }

} // .block-minicart .block-content




// ===================== Обратный звонок ===========================
.ordercall-modal {
    .actions-toolbar {
        text-align: center;
        padding-bottom: 1rem;
        padding-top: 0.5rem;
    }
}






// Маленький хедер ------------------------------------------------------------------------
@include breakpoint(small down) { 
    .page-header {
        height: $height_header_small;

        .sticky-top {
            background: white;
        }

        .panel.wrapper, .nav-sections {
            display: none;
        }

        .header-content { 
            height: $height_header-content_small;
            .top-bar {
                // Прячем лого
                .top-bar-middle {
                    display: none;
                }
                // Корзина в центре
                .top-bar-right {
                    width: 100%;

                    // Прячем границу
                    .header-subtotal {
                        text-align: right;

                        .phone {
                            div {
                                border-left: 0; border-radius: 0;
                            }
                            div, .order-call {
                                border-top: 0; border-bottom: 0;          
                            }
                            .order-call {
                                border-right: 1px solid $fg-vio-pastel2;
                            }
                        }
                        // Сумма
                        .subtotal-to-checkout .action.viewcart {
                            border-top: 0; border-bottom: 0;
                            @include breakpoint(365px down) { 
                                display: none;
                            } 
                        }
                        // Корзина
                        .minicart-wrapper .action.showcart {
                            border-radius: 0;
                            border-top: 0; border-bottom: 0; border-right: 0;
                        }
                    }
                }
            }
        } 

    }
}



// off-canvas
.small-header {
    //@include box-shadow(0 10px 10px -10px hsb(0, 0, 0, 0.4));
    //position: fixed; 
    width: 100%;
    //z-index: 10;

    height: $height_small-header;

    .title-bar{
        text-align: center;
        background: $fg-primary;
    }
    .title-bar-center{
        display: inline-block;
        border-bottom: 2px solid $fg-secondary;
        a {
            color: $bg-body;
        }

        .title-bar-title {
            margin-left: 0.5rem;
        }
    }
}

.is-off-canvas-open {
    overflow: visible;
}

// Контейнер сайта #mainContainer
.off-canvas-content{
    @include breakpoint(small only){
        //padding-top: $small-header-height;
    }
    // Убираем бокс-шедоу сверху
    @include breakpoint(medium){
        box-shadow: none;
    }

}

.off-canvas{
    //height: 100%; // для равномерной заливки
    background: darken($bg-body, 5);
    .menu{
        li{
            border-bottom: 4px solid darken($bg-body, 15);           
            background-color: $bg-body;

            &.heading{
                padding: 0.5rem 1rem;
                border-bottom: 1px solid black;
            }

            a {
                color: $fg-primary;
                font-family: $f-Gabriela;
            }

            &.active {
                >a {
                    //@include link-black-orange;
                }
            }

            &:hover, &.active{
                background-color: $fg-primary;
                color: $bg-body;
                border-color: $fg-secondary;
                text-shadow: 0 0 1px $bg-body;

                >a {
                    color: $bg-body;
                    //background-color: inherit;
                }
            }
        }
    }

    // Заголовок off-canvas
    // Имя пользователя
    .customer-name, >.title {
        display: block;
        font-family: Marmelad;
        font-size: 1.3rem;        
        text-align: center;
        padding: 0.5rem;
        color: $fg-vio-dark2;


        span {
            border-bottom: 2px solid $fg-secondary;
        }
    }
}


#offCanvasRight {
    // Кнопка войти 
    li.authorization-link a{
        i {color: $fg-icon-green;}
    }
    // Мои пожелания
    li.wishlist {
        i {color: $fg-icon-red;}
    }
    // Мой аккаунт
    li.account-link {
        i {color: $fg-icon-green;}
    }


    #menu-top-right-small {
        .authorization-link:only-child {
            display: none;
        }
    }

}





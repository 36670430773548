// 


// anons-index
@mixin wg-main_anons-index {
    .menu {
        li {
            position: relative; // для стрелки
            .date {
                color: $fg-red;

                // Стрелка
                &:before {
                    top: 0.55rem;
                    left: 0.3rem;
                    border: solid transparent;
                    content: " ";
                    position: absolute;
                    pointer-events: none;
                    border-left-color: $fg-red;
                    border-width: 5px;
                }
            }
            a {
                padding: 0 0.8rem 1rem 0;
                transition: color 0.3s ease-in-out 0.0s;
                color: $fg-almost-black;
                &:hover{
                    color: $fg-secondary;
                    text-decoration: underline;
                }
            }
        }
    }
}

// news-listing
@mixin wg-main_news-listing {
    @include wg-main_anons-index;

    .menu li {
        line-height: 1.2;
        padding-top: 1rem;
        border-bottom: 1px dashed $fg-blue;

        .date {

            // Стрелка
            &:before {
                top: 1.3rem;
            }
        }
    }

    .row .link-column {
        padding-left: 0.5rem;
    }
}

// banner-anons
@mixin wg-main_banner-anons {

    // контейнер .orbit
    .banner-anons { 

    }

    $slide-height: 340px;
    $img-height: 300px;
    // div
    .orbit { 
        // ul
        .orbit-container {  
            max-height: $slide-height;

            .orbit-slide {
                // Ссылка-обретка слайда
                >a {}
                // Конейнер содержимого слайда
                .container {
                    overflow: hidden;
                    background: linear-gradient(
                        to right, 
                        rgba($white, 0.0) 0%,
                        rgba($white, 0.6) 30%,
                        rgba($white, 0.0) 100%
                        );
                    //background: rgba($white, 0.6);

                    height: $slide-height;

                    .anons {
                        padding-top: 2rem;
                        paddong-bottom: 1rem;
                        text-align: right;
                        line-height: 1.2;

                        >div {
                            float: right;
                            width: 70%;
                        }

                        .date {
                            color: $fg-primary;
                            font-size: 1.3rem;
                            line-height: 1;
                        }
                        .title {
                            color: $fg-h;
                            font-family: "Times New Roman",times,serif;
                            font-style: oblique;
                            line-height: 1;
                            font-size: 1.5rem;
                            padding-top: 0.5rem;
                            width: 70%;
                        }
                        .details {
                            color: $fg-p;
                            font-size: 0.95rem;
                            margin-top: 0.5rem;
                            padding: 0.3rem 0.5rem;
                            //width: 100%;

                            background: linear-gradient(
                                to left, 
                                rgba($white, 1),
                                rgba($white, 0.2)

                                );

                            >* {
                                line-height: inherit;
                                margin: 0;
                            }

                        }
                    }

                    .image {
                        padding: 0;
                        height: 100%;
                        line-height: $slide-height;

                        .img-container {
                            display: inline-block;
                            float: right;
                            text-align: right;
                            max-width: 100%;

                            img{
                                max-height: $img-height;
                                //max-width: 100%;
                                vertical-align: bottom;

                                $img-border: 10px solid rgba(black, 0.15);                               
                                border-top: $img-border;
                                border-left: $img-border;
                            }
                        }
                    }
                }
            }


        }

        // nav
        .orbit-bullets {
            button:not(:hover):not(.is-active) {
                background-color: darken($white, 30%);
            }
        }
    }
}


// dic-listing
@mixin wg-main_dic-listing { 
    h3 {
        box-shadow: none;
        border-bottom: 4px dotted red;
        display: block;
        font-size: 30px;
        color: brown;
        padding: 5px;
        margin-top: 1.5rem;
    }

    .wg-main.dic {

        li {
            padding: 0;

            a {
                @include link-blue;
                font-size: 19px;
                text-decoration: none;                             
                
                padding-left: 0;
                padding-right: 0.5rem;
                
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }

}



// Catalog

// ++++++++++ Категории
// ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++


// Текстовое описание категорий
.category-view {
    .category-description {
        font-family: $f-Marmelad;
    }
    &.top, &.bottom {
        padding-bottom: 1rem;
    }
}




// Toolbar
.toolbar.toolbar-products {}

@mixin toolbar-font {
    font-family: marmelad;
    font-size: 0.9rem;
    color: $fg-blue3;
}

@mixin toolbar-limiter {
    .limiter {      
        >*, select {
            display: inline-block;
            vertical-align: middle;
            @include toolbar-font;
        }
        label, .limiter-label { display: none;}
        select {
            width: auto;
            margin-bottom: 0;
        }
        .limiter-text {}
    }
}



.toolbar {
    display: inline-block;

    // Выравнивание влево и вправо
    text-align: justify;
    width: 100%;

    &:after{
        content: '';
        display: inline-block;
        width: 100%;
    }

    >* {
        display: inline-block;
        vertical-align: middle;
    }

    // Сетка список
    .modes {
        display: none;
        .tabs {  
            border: 1px solid $fg-input-border;
            border-radius: 6px;
            .tabs-title  {
                >a {
                    display: inline-block;
                    font-size: 1.4rem;
                    padding: 0.25rem 0.5rem; 
                    color: $fg-vio-dark2;

                    i {
                        vertical-align: middle;
                    }

                    &[aria-selected="true"]{
                        background: $fg-input-border;
                        color: white;
                        cursor: default;
                    }
                    &:focus{
                        background: inherit;
                        color: $fg-vio-dark2;
                    }
                }
            }
        }
    }
    // Позиции
    .toolbar-amount {
        margin: 0 0.5rem;
        border-bottom: medium dotted;
        @include toolbar-font;
    }
    // Пейджер
    .pages {
        .pagination {
            margin-bottom: 0;
        }
    }
    // Показать на странице
    @include toolbar-limiter;
    .field.limiter {
        >*, select {

        }
        .control {
            select { }
        }
        .limiter-text {}
    }

    // Сортировка
    .toolbar-sorter {
        @include toolbar-font;
        >* {
            display: inline-block;
            vertical-align: middle;
            @include toolbar-font;
        }
        label { display: none;}
        select {
            margin-bottom: 0;
            width: auto;          
        }
        .sorter-action {}
    }
}

// Верхний и нижний тулбары
.toolbar-products.top {
    /*.pages,*/ .field.limiter {
        display: none;
    }
}
.toolbar-products.bottom {
    .modes, .toolbar-amount, .toolbar-sorter {
        display: none;
    }
}


// Листинг товаров
.products-grid {
    .product-items {
        // Баг отступами
        margin: 0 -0.5rem;
        padding: 6px
    }
}

@mixin price-font {
    font-family: $f-OpenSansCon;
    color: $fg-vio-dark2;
}

@mixin price-box {
    .price-box {
        @include price-font;
        font-size: 1.6rem;
        text-align: center;
        margin-bottom: 0.5rem;
        .price {
            span {
                font-size: 0.7em;
            }
            i {
                font-size: 0.55em;
            }
        }

        .special-price {
            color: $fg-blue3;
        }

        // Перечеркнутая цена
        .old-price {
            font-size: 0.8em;
            .price-label {
                display: none;
            }
            .price {

                text-decoration: line-through;
            }
        }
    }
}

@mixin reviews-summary ($_star-size: 16px, $_width: 88px) {
    // Звездочки
    .rating-summary {
        overflow: hidden;
        white-space: nowrap;
        display: inline-block;

        .label {display: none}
        .rating-result {
            position: relative;
            vertical-align: middle;
            width: $_width;
            // Звездочки
            &:before, > span:before {
                @include af-icon(); 
                content: $fa-var-yingyang $fa-var-yingyang $fa-var-yingyang $fa-var-yingyang $fa-var-yingyang;

                color: #c7c7c7;
                display: block;
                font-size: $_star-size;
                height: $_star-size;
                left: 0;
                letter-spacing: 2px;
                line-height: $_star-size;
                position: absolute;
                top: 0;
                width: 100%;
                //z-index: 1;
            }

            >span {
                display: block;
                overflow: hidden;
                &:before {
                    color: $fg-fivy;
                    position: relative;
                    width: auto;
                }
                >span {display: none}
            }

        }
    }
}



@mixin product-reviews-summary ($_star-size: 16px, $_width: 88px) {
    // Рейтинг и отзывы
    .product-reviews-summary {
        margin-bottom: 0.5rem;
        text-align: center;

        &.short {}
        >*{
            display: inline-block;
            vertical-align: middle;
        }

        @include reviews-summary ($_star-size, $_width);
        // Звездочки
        .rating-summary {
        }
        // Отзывы
        .reviews-actions {
            font-size: 11px;
            //margin-top: 5px;
            text-transform: lowercase;
            // ссылка
            .action.view {}
        }
    }
}

@mixin product-item-stock {
    // Надпись есть на складе/ нет на складе
    // добавлять внутри .stock
    font-family: $f-Marmelad;
    font-size: 0.8rem;
    color: $fg-vio;
    &.unavailable {

    }
}

@mixin product-item-photo {
    display: block;
    text-align: center;
    width: 100%;
    position: relative;
}

@mixin product-item-discount {
    $size: 3rem;
    position: absolute;
    font-family: $f-OpenSansCon;
    font-size: 1.3rem;
    height: $size;
    width: $size;
    line-height: $size;
    border-radius: 1.5rem;
    color: white;
    background-color: $fg-icon-green;

    top: 1rem; left: 1rem;
}

@mixin product-item-name-text {
    font-family: $f-Gabriela;
    display: block;
    font-size: 0.9rem;
    margin-bottom: 0.5rem;
}

@mixin product-item-name {
    text-align: center;
    // link
    a {
        @include product-item-name-text;
    }
}

@mixin product-item-actions {
    .product-item-actions {
        height: 30px;
        >* {
            display: inline-block;
            vertical-align: middle;
        }
        // Кнопка добавить в корзину
        .actions-primary, .actions-secondary {
            //display: table-cell;
            margin-bottom: 0.3rem;
            //height: 30px;
        }

        .actions-primary {
            padding-right: 0.5rem;
            // Кнопка
            .tocart {
                font-size: 0.8rem;
                margin-bottom: 0;
                padding: 0.4rem 0.5rem;
            }
            // Надпись есть на складе/ нет на складе
            .stock {
                @include product-item-stock;
            }

        }
        // Кнопки доп.
        .actions-secondary {
            .action {
                font-size: 1.2rem; 
            }
            .tocompare {
                @include icon-compare;
            }
            .towishlist {
                @include icon-heart;
            }
        }
    }
}




// Общее
.product-items {  

    .product-item {
        padding: 0 0.5rem;

        // .card
        .product-item-info.card {
            transition: $trans-bs-color;
            &:hover {
                box-shadow: 0 0 4px 1px $fg-input-border;
            }
        }

        // .card
        .product-item-info {
            // a > img
            .product-item-photo {
                // Выравнивание по центру для картинок уже ширины card
                @include product-item-photo;                

                // Скидка
                .discount {
                    @include product-item-discount;
                }
            }
            // Нет в наличии - сообщить о поступлении
            &.out-of-stock {
                .product-item-photo { 
                    position: relative;

                    img {
                        //filter: blur(2px);                         
                    }
                    &:hover {
                        img {
                            // filter: blur(0px);                         
                        }
                    }
                    &:after {
                        content: "Сообщить о поступлении";
                        //padding: 0.5rem;
                        //border: 0px solid $fg-input-border;
                        //border-radius: 0.5rem;
                        background: rgba($fg-vio-pastel, 1.0);
                        color: $fg-vio-dark2; //$fg-tertiary;

                        position: absolute;
                        height: 2rem; line-height: 2rem; width: 100%;
                        bottom: 3%; left: 0;

                        font-family: $f-Marmelad;
                        font-size: 0.8rem;

                        overflow: hidden;
                    }
                }
            }


            // .card-section
            .product-item-details {
                padding-top: 0;
                // Название
                .product-item-name {                   
                    @include product-item-name;
                }
                // Рейтинг и отзывы
                @include product-reviews-summary;
                .product-reviews-summary {
                }


                @include price-box;
                // Цена
                .price-box {}
                // Кнопки
                .product-item-inner {
                    text-align: center;

                    @include product-item-actions;
                }
            }

        }
    }    
}

@mixin product-item-name-fixed {
    display: block;
    height: 55px;
    overflow: hidden;
    margin-bottom: 5px;
}

// Фиксированные карточки в категориях
.catalog-category-view, // просмотр категорий
.catalogsearch-result-index, // результаты поиска
.checkout-cart-index, // корзина
.block-viewed-products-list, // недавно просмотренные товары
{
    .product-items .product-item .product-item-info.card {
        //height: 430px;
        //position: relative;
        .product-image-photo {
            //height: 250px;
        }

        .product-item-name {
            @include product-item-name-fixed;
        }
        .product-item-inner {
            display: block;
            height: 28px;
        }


    } 
}






// ++++++++++ Продукты
// ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++


.main-column {
    .product-info-first-row {}
}

// Картинка
.product.media {

    // Страрая фиксированная картинка / выключено
    .image-wrapper {
        padding: 0.6rem;
    }

    // Фотораама
    .gallery-placeholder {
        padding: 0.5rem;

        // Основная картинка
        .fotorama__stage {
            @include thumbnail;
        }

        // Превьюшки
        .fotorama__nav-wrap {
            margin-top: 0.6rem;

            // Активная превьюшка
            .fotorama__thumb-border {
                border-radius: 0.3rem;
                border-color: $fg-vio;
            }
        }

        // увеличитель
        .magnifier-large {
            z-index: 1;
        }
    }
}


@mixin product-reviews-actions {
    .reviews-actions {
        font-family: $f-Marmelad;
        font-size: 0.9rem;

        display: block;
        margin-top: 0.5rem;

        // Количество отзывов
        .action.view {
            margin-right: 1rem;
        }

        // Добавить отзыв
        .action.add {
            &:before {
                @include af-icon();                       
                content: $fa-var-yingyang-scetch;
                color: $fg-tertiary; //$fg-icon-red;
                vertical-align: middle;
                width: 1.2rem;
            }
        }
    }
}



// Описание товара
.product-info-main {
    // h1
    .content-wrapper.page-title-wrapper.product {
        .page-title {
            padding-left: 0;
            margin-top: 0.5rem;
        }
    }
    // Описание
    .product-info-price {
        // Отзывы
        @include product-reviews-summary (32px, auto);
        .product-reviews-summary {
            text-align: left;
            &.empty {}

            @include product-reviews-actions;
            .reviews-actions {
            }

        }
        // Цена
        @include price-box;
        .price-box {
            font-size: 3.1rem; //3.5rem;
            text-align: left;
            .price {
                span {
                    font-size: 0.7em;
                }
            }
        }
        // Наличие, СКУ
        .product-info-stock-sku {
            // Наличие
            .stock {
                font-family: $f-Marmelad;
                font-size: 0.9rem;
                color: $fg-vio;
                &.unavailable {

                }
            }
        }

    }


    // Ску
    .attribute.sku {
        font-family: $f-OpenSansCon;
        font-size: 1.3rem; //1.5rem; //1.8rem;
        color: $fg-blue2;

        padding-left: 0.1rem;
        padding-top: 0.3rem;

        >* {
            display: inline;
        }
        .type {                    
            &:after {
                content: ":";
                display: inline;
            }
        }
    }



    // Количество и добавить в корзину
    .product-add-form {
        margin-top: 1rem;

        // выравнивание в одну линию с .product-social-links
        display: inline-block;
        margin-right: 1rem;

        .box-tocart {}      
        .fieldset {
            border: 0; padding: 0; margin: 0;

            >*, >*>* {
                display: inline-block;
                vertical-align: middle;
            }

            .label {display: none;}

            .field.qty {
                margin-bottom: 0;
                margin-right: 1rem;

                input {
                    @include product-qty-input;
                    font-size: 1.5rem;
                    height: auto;
                    width: 3rem;

                    background: transparent; // чтобы readonly не был серым
                }

                // Кнопки количества
                .QtyButton {
                    font-size: 1.5rem;
                }
            }

            // Кнопка добавить в корзину
            .actions {}
            .action.tocart {
                margin-bottom: 0;
                font-size: 1.2rem;
            }
        }

    }

    // Уведомить о поступлении
    .product.alert.stock {
        margin-top: 1rem;
    }
    .stock-notifier-form {
        input {
            //margin-bottom: 0.5rem;
        }

        .action-toolbar { 
            padding: 0.5rem;
            border-bottom: 1px solid $fg-vio-pastel3;

            .sign-up{
                font-family: $f-Gabriela;
                @include link-vio;
                text-align: center;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }



    // Кнопки в список пожеланий, добавить в сравнение
    .product-social-links {
        //margin-top: 0.5rem;

        // выравнивание в одну линию с .product-social-links
        display: inline-block;
        vertical-align: middle;

        .product-addto-links {
            .action {
                font-size: 1.8rem; 
                margin-right: 0.4rem;
            }
            .tocompare {
                @include icon-compare;
            }
            .towishlist {
                @include icon-heart;
            }
        }
    }

    // Краткое описание товара
    .product.attribute.overview {
        font-family: $f-Marmelad;

        margin-top: 1rem;
        border-top: 1px solid $fg-blue-pastel;
    }


    // Дополнительная информация - table
    .additional-attributes {
        margin-bottom: 0;
        width: auto;
        text-align: left;
        font-family: $f-OpenSansCon;
        font-size: 1.3rem;
        color: $fg-vio-dark;

        tbody {
            background-color: transparent; //rgba(white, 0.5);
            tr:nth-child(2n) {
                background: transparent;
            }
            td, th {
                padding-bottom: 0;
                padding-left: 0;
            }
        }
        .col.name {

        }
    }

    // Отзывы
    @include product-reviews-summary (32px, auto);
    .product-reviews-summary {
        text-align: left;
        &.empty {}

        @include product-reviews-actions;
        .reviews-actions {
        }

    }

    // Окончание +++++++++++++++
    // .content-wrapper.page-title-wrapper.product

}




// Табы ++++++++++++++++++++++++++
.product.info.detailed {
    margin-top: 2rem;
    // Контейнер табов
    .product.data.items {
        //Закладки
        .tabs {

        }
        // Содержимое закладок
        .tabs-content {

            // Продробности
            .attribute.description {
                .value {
                    font-family: $f-Marmelad;
                }
            }


            // Отзывы
            .block.review-list {
                .block-title {display: none;}

                .block-content {
                    // Тулбар
                    .toolbar {
                        text-align: right;
                        &:after {display: none;}


                        @include toolbar-limiter;
                        .limiter {

                            .limiter-text {}
                        }
                    }
                }
            }
        }
    }
}



// Связанные продукты, виджеты и блоги на главной +++++++++++++++++++++++++++++++++++++++++++++++++++++++++

.block.upsell, .block.crosssell, .block.related,
.block-new-products, .widget.block-products-list,
.catalog-index-container, .blog-widget-recent,
.blog-categories-index-container
{
    margin-top: 2.5rem;
    .block-title {
        @include content-header;
        text-align: center;
        font-size: 1.5rem;
        margin-bottom: 1rem;
    }
    .product-item-name {
        @include product-item-name-fixed;
    }

    .product-item-photo { 
        img {
            //height: 190px;
        }
    }
}

.block-new-products {
    margin-top: 2rem;
}

// В корзине
.checkout-cart-index {
    .block.upsell, .block.crosssell, .block.related,
    .block-new-products, .widget.block-products-list
    {
        .product-item-photo { 
            img {
                height: 240px;
            }
        }
    }
}




// Сравнение товаров ++++++++++++++++++++++++++++++++++++++++++

.catalog-product-compare-index {
    .action.print {
        @include link-vio;
        font-family: $f-Gabriela;

        display: none;
    }
}

.table-wrapper.comparison {
    @include order-font;
    margin-top: 1rem;

    .table-caption {
        display: none;
    }

    table {
        @include table-transparent;
    }

    thead {
        .name {
            span {
                display: none;
            }
        }
        td {
            text-align: center;
            a {
                @include link-red;
                font-size: 1.2rem;
            }
        }
    }

    // Подпись
    .cell.name {
        text-align: right;
    }

    // Ячейка товара
    .cell.product.info {
        .product-item-photo {
            display: block;
            text-align: center;
            width: 100%;
        }

        .product-item-name {
            @include product-item-name;
        }

        @include product-reviews-summary;
        @include price-box;

        .product-item-inner {
            text-align: center;
            @include product-item-actions;
        }
    }

    // Атрибуты товара
    .cell.product.attribute {
        text-align: center;
        font-size: 1.1rem;
    }
}

// Расширенный поиск +++++++++++++++++++++++++++++++++

.catalogsearch-advanced-index {  
}
.form-search-advanced-wrapper {
}

// Результат расширенного поиска

.catalogsearch-advanced-result {
    .search.found {
        @include field-note;
    }

    .search.summary {
        @include order-font;
        @include order-content;
        margin: 0.5rem 0 1rem;

        .items {
            list-style-type: none;           
        }
    }
}

.catalogsearch-advanced-result,
.catalogsearch-result-index {

    .search.results {
        margin-top: 1rem;
        margin-bottom: 0.5rem;
    }
}


// Каталог на главной +++++++++++++++++++++++++++++++++++++

.catalog-index-container {
    .column-block {

    }

    .img-container, .links-container {
        float: left;
    }

    .img-container {
        img {
            //max-width: 250px;
        }
    }

    .menu {
        font-family: $f-Gabriela;

        .parent {
            > a {
                font-size: 1.3rem;
            }

            ul {
                margin-left: 2rem;
                li {
                    a {
                        font-size: 1.1rem;
                        padding: 0.4rem 0;
                    }  
                }
            }
        }
    }
}


// **************** Кнопки количества товара ++++++++++++++++++

// Кнопки количества
.QtyButton {
    cursor: pointer;
    &_minus {
        @include icon-remove;
    }
    &_plus {
        @include icon-edit;
    }
    &:disabled {
        cursor: not-allowed;
        color: gray;
    }
}



// 


// sitemap
@mixin wg-sitemap_sitemap {
    @include wg-nav_current-node;
    .container {
        border-top: 0;

        .menu {
            li {
                .link {
                    font-size: 1.2rem;
                    text-decoration: none;
                }
            }

            $sitemap-padding: 1.25rem;
            // Уровни
            .depth-0 .link {
                padding: 0.4rem 0;
            }
            .depth-1 .link {
                padding: 0.35rem 0;
                padding-left: $sitemap-padding;
                font-size: 1.18rem;
            }
            .depth-2 .link {
                padding: 0.30rem 0;
                padding-left: $sitemap-padding * 2;
                font-size: 1.16rem;
            }
            .depth-3 .link {
                padding: 0.25rem 0;
                padding-left: $sitemap-padding * 3;
                font-size: 1.14rem;
            }
            .depth-4 .link {
                padding: 0.20rem 0;
                padding-left: $sitemap-padding * 4;
                font-size: 1.12rem;
            }
            .depth-5 .link, .depth-6 .link, .depth-7 .link, .depth-8 .link, .depth-9 .link {
                padding: 0.15rem 0;
                padding-left: $sitemap-padding * 5;
                font-size: 1.1rem;
            }
        }
    }    
}


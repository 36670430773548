

// Корневая страница блога

.video-index-index {
    .sidebar {
        &.sidebar-additional {
            margin-top: 0;
        }
    }       
}   

.sidebar {
    // Поиск
    @include block-search;
    .video-search {
        width: 500px;
        .label {
            display: none;
        }
    }

    .widget.video.block-categories,
    .widget.block-recent-posts,
    .widget.block-tagclaud,
    {
        margin-top: 1rem;
        font-family: $f-Marmelad;

        .block-title {
            @include content-header;
        }
    }

    //Категории
    .widget.video.block-categories {
        ul {
            list-style-type: none;
            margin-left: 0;

            .wrapper {
                display: block;
                border-bottom: 1px dotted $fg-blue-deep2;
            }

            .count {
                display: inline-block;
                float: right;
                color: $fg-icon-green;
            }
        }
    }  

    // Недавние публикации
    .widget.block-recent-posts {
        margin-top: 2rem;

        .block-content {
            .item {
                padding: 0;
            }
        }
    }

    // Облако тегов
    .widget.block-tagclaud {
        margin-top: 2rem;

        .tagclaud-hld {
            font-family: $f-OpenSansCon;

            span {
                display: inline-block;
                padding: 0.3rem;
            }
        }
    }
    
    // Связанные видео
    .block.video-related {
        margin-bottom: 0.5rem;
    }


} 



// Листинг постов ++++++++++++++++

// Текстовое описание категории
.category-content {
    font-family: $f-Marmelad;
}

.toolbar-video-posts {
    text-align: center;
}

// ul
ol.post-list, .post-view {
    margin-left: 0;



    // li
    .post-holder {
        background-color: rgba($fg-vio-pastel2, 0.15);
        border-radius: 1rem;

        &:hover {
            box-shadow: 0 3px 4px 0 rgba(black, 0.1);
        }


        .post-header {
            .post-title {
                padding: 0;

                .post-item-link {
                    @include link-blue;
                }

            }

            // соцсети
            .addthis_toolbox.addthis_default_style {
                padding-top: 0;
                min-width: 80px;
                min-height: 25px;
            }

            // Категории, опубликовано
            .post-info {
                padding: 0.5rem 0;

                @include order-font;

                .post-categories {
                    .title {
                        font-weight: bold;
                        color: $fg-vio-dark2;
                    }
                    a {
                        @include link-blue;
                        margin: 0;
                        margin-left: 0.5rem;
                    }
                }
            }

        }

        // Пост
        .post-content {
            .post-description {
                // Картинка ++
                .post-ftimg-hld, .post-text-hld {
                    margin: 0;
                    padding: 0.5rem;
                }
                .post-ftimg-hld {
                    padding-right: 1rem;
                }

                .post-text-hld {
                    font-family: $f-Marmelad;
                    text-align: justify;                   
                }

                @include signature;

                .post-read-more-wrapper {
                    border-top: 1px dashed $fg-blue-pale;
                }
                .post-read-more {
                    font-family: $f-Gabriela;
                    @include link-vio;
                }
            }
        }


        // Комменты и связанные посты
        .post-bottom {
            margin-top: 1rem;

            // Навигатор по страницам
            .post-nextprev-hld {
                font-family: $f-Gabriela;
            }
        }


    }

}


// Просмотр поста

.video-post-view {
    .post-view {
        margin-left: 0;

        // li
        .post-holder {

            .post-content {
                .post-description {
                    // Картинка ++
                    .post-ftimg-hld {
                        box-sizing: content-box;
                        padding-right: 1.5rem;
                    }
                }
            }
        }
    }

    .block.related {

    }
}

// Недавние посты на главной ++++++++++++++++++
// Категории постов на главной ++++++++++++++++++
.video-widget-recent, .video-categories-index-container {
    .post-list {
        padding: 0.5rem 0.8rem;

    }

    .post-item {
        padding: 0 0.5rem;

        // .card
        .post-item-info.card {
            transition: $trans-bs-color;
            &:hover {
                box-shadow: 0 0 4px 1px $fg-input-border;
            }
        }

        .post-item-info {
            // a > img
            .post-item-photo  {
                // Выравнивание по центру для картинок уже ширины card
                @include product-item-photo;   
                a img, img {
                    max-height: 250px;
                }
            }

            // .card-section
            .post-item-details {
                padding-top: 0;
                // Название
                .post-item-name {   
                    @include product-item-name;
                }
            }
        }
    }

}

// Категории постов на главной ++++++++++++++++++

.video-categories-index-container {
    .post-item {
        &:hover {
            cursor: pointer;
        }
        .post-item-info {
            // .card-section
            .post-item-details {
                // Название
                .post-item-name {   
                    @include product-item-name-text;
                    @include link-blue;
                }
            }
        }
    }
}




// Content
.content-wrapper {
    float: none;  // Для правильного вывода блоков за контентом
    //padding-top: rem-calc(10);

    text-align: justify;

    //font-family: Arial, Tahoma, Verdana, sans-serif;
    font-family: $f-Marmelad;
    
    font-size: 1rem;  
    @include breakpoint(small only){
        font-size: 1.2rem;
    }    
    line-height: 1.2;
    color: $fg-p;

    .row {
        padding-top: rem-calc(6);
    }


    p {
        line-height: 1.2;
        margin-bottom: 0.5rem;
    }

    a:not(.button) {
        text-decoration: underline;

        &:visited{
            //color: #551A8B; //blueviolet - дефолтный
            color: #8b1958;
        }
    }
    a:not([href]) {
        text-decoration: none;        
    }


    h1, h2, h3, h4, h5, h6 {
        @include content-header;       
        
        
        /*font-family: $f-Gabriela;
        color: $fg-h;
        border-bottom: 1px solid $fg-blue-pastel;*/
        
        //box-shadow: 6px 0 inset, 9px 0 0 0 $fg-blue-water inset;        
        /*padding-left: 0.9rem; // для границы слева
        padding-bottom: 0.1rem;
        text-align: left;
        line-height: 1;

        font-weight: normal;*/
    }
    h1 {
        //margin-bottom: rem-calc(15);
    }
    h2 {
        border-bottom-width: 2px;
        padding-top: 0.5rem;
    }
    h3, h4, h5, h6 {
        border-bottom-width: 1px;
        padding-top: 0.5rem;
    }

    hr {
        border-color: $fg-p;
    }

    // Заголовки таблиц, картинок  
    // Поверх старого форматирования
    p>span {
       // font-size: 0.9rem !important;
        //font-weight: bold;
    }
    // Новый стиль
    p.pic-header {
        text-align: center;
        font-size: 0.9rem !important;
        font-weight: bold;
    }

    // Комментарий таблиц, картинок  
    // Поверх старого форматирования
    p>em>span {
        font-size: 0.8rem !important;
    }
    // Новый стиль
    p.pic-comment {
        text-align: center;
        font-size: 0.8rem !important;
        font-style: oblique;
    }


    // Картинки
    // Поверх старого форматирования
    p>img {
        height: auto !important;
    }

    table {
        font-size: 0.7rem;
        max-width: 100%;
    }

    .tbl-border-1 {
        width: auto;
        margin: 0 auto 20px;
        font-size: 0.7rem;
        td, th {
            border: 1px solid $dark-gray;
            padding: 5px;

            p {
                margin-bottom: 0;
            }
        }
    }


    // Список
    ul:not(.slick-dots):not(.orbit-container):not(.social):not(.ul-clear){
        list-style: none;
        //color: $bg-header-TopBar;
        padding: 0 0 0 1rem;
        margin-left: 0; // сбрасываем дефолтный отступ из-за маркеров

        li {
            padding-left: 1.1rem;
            padding-bottom: 0.6rem;
            line-height: 1.2;
            text-align: left;

            &:before {
                content: "\f00c"; /* FontAwesome Unicode */
                font-family: FontAwesome;
                display: inline-block;
                right: 0.5rem;
                margin-left: -1.5rem; /* same as padding-left set on li */
                width: 1.5rem; /* same as padding-left set on li */
                //margin-right: 0.3rem;
                color: $fg-h;
            }
        }
        ul, ol {
            margin-top: 0.5rem;
        }
    }
    ol{
        padding-left: 1.5rem;
        li {
            padding-left: 1.1rem;
            padding-bottom: 0.6rem;
            line-height: 1.2;
            text-align: left;
        }
        ul, ol {
            margin-top: 0.5rem;
        }
    }

    .w-10 {max-width: 10%;}
    .w-20 {max-width: 20%;}
    .w-30 {max-width: 30%;}
    .w-40 {max-width: 40%;}
    .w-50 {max-width: 50%;}
    .w-60 {max-width: 60%;}
    .w-70 {max-width: 70%;}
    .w-80 {max-width: 80%;}
    .w-90 {max-width: 90%;}
    .w-100 {max-width: 100%;}

}


// Лайтбокс
.content-wrapper {
    a[data-lightbox] {
        text-decoration: none;

        img {
            margin-bottom: 0.5rem;
        }
        .caption {
            font-size: 0.8rem;
            font-weight: normal;
            color: $fg-blue;

            display: block;
            text-align: right;
        }
        // Выравнивание подписи по центру, если картинка меньше 100%
        img.float-center:not(.w-100) + .caption {
            text-align: center;
        }
        img.float-center + .caption.text-center {
            text-align: center;
        }
    }
}

// Цитирование
.content-wrapper {
    // Общее для всех цитат
    blockquote, blockquote p {
        color: #767676;
    }

    // Кроме твитов
    blockquote:not(.twitter-tweet) { 
        border-left: 10px solid white;
        margin: 1.5em 10px;
        padding: 0.5em 10px;
        quotes: "\201C""\201D""\2018""\2019";
        line-height: 1.3;       

        &:before {
            color: white;
            content: "\201C";
            font-size: 4em;
            line-height: 0.1em;
            vertical-align: -0.4em;
        }
        p {
            color: rgba($fg-h, 0.88);

            // первый параграф
            &:first-child {
                display: inline;

                // второй параграф
                & + p {
                    margin-top: 1rem;
                }
            }

            // последний параграф
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

// Твиты
.content-wrapper {
    blockquote.twitter-tweet {
        border: 0.1rem solid #dcdcdc;
        border-radius: 0.3rem;
        //font-family: Helvetica,Roboto,"Segoe UI",Calibri,sans-serif;
        font-size: 0.875rem;
        margin: 0 0 0.75rem;
        padding: 1.25rem; 
    }
    // emged
    iframe.twitter-tweet {
        margin: 10px auto;
    }
}


// Видео

.content-wrapper {
    .flex-video {
        &.thumbnail {
            display: block;
        }

        iframe {

        }
    }
}

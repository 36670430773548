// Removes outline on hover
// but should not prevent keyboard users from getting feedback

textarea:hover,
input:hover,
textarea:active,
input:active,
textarea:focus,
input:focus,
button:focus,
button:active,
button:hover
{
    outline:0px !important;
    //-webkit-appearance:none;
}

// yes, but there is outline on links by means of underline upon focus
*:focus {
    outline: 0 !important;
}

/* Always show the scrollbar to prevent "jumping" */
html {
    overflow: -moz-scrollbars-vertical;
    overflow-y: scroll;
}

html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizelegibility;
}


// Fix ie11
@media all and (-ms-high-contrast:none)
{
    //.foo { color: green } /* IE10 */
    *::-ms-backdrop, .main-header .two.container .three::before { 
        //  margin-left: -1px;
    } /* IE11 */
}

// Стрелка по умолчанию
select {
    -webkit-appearance: none;
    -moz-appearance: none;
}

// .panel.header

.action.skip{
    &:focus {
        background: #f0f0f0 none repeat scroll 0 0;
        box-sizing: border-box;
        left: 0;
        padding: 10px;
        position: absolute;
        text-align: center;
        top: 0;
        width: 100%;
        z-index: 15;
    }
}


.multicheckout .table-wrapper .col .label,
.multicheckout .block .methods-shipping .item-content .fieldset > .legend,
.opc-wrapper .form-discount .field .label,
.bundle-options-container .block-bundle-summary .product-image-container,
.bundle-options-container .block-bundle-summary .product.name,
.bundle-options-container .block-bundle-summary .stock,
/*.product-item-actions .actions-secondary > .action span,*/ .special-price .price-label,
.table-comparison .cell.label.remove span, .table-comparison .cell.label.product span,
.block.related .field.choice .label, .cart.table-wrapper .col.qty .label,
.minicart-wrapper .action.showcart .text, .minicart-wrapper .action.showcart .counter-label,
.checkout-index-index .page-title-wrapper, .checkout-onepage-success .page-title-wrapper,
.checkout-payment-method .field-select-billing > .label, .checkout-payment-method .payments .legend,
.checkout-payment-method .ccard .legend, .field.street .field.additional .label, 
.block-balance .balance-price-label,
.page-product-downloadable .product-options-wrapper .fieldset .legend.links-title,
.page-product-giftcard .fieldset.giftcard > .legend, .opc-wrapper .form-giftcard-account .field .label,
.form-add-invitations .fields .label, .filter-options-content .filter-count-label,
.table-comparison .wishlist.split.button > .action.split span,
.product-items .wishlist.split.button > .action.split span,
.page-multiple-wishlist .page-title-wrapper .page-title,
.block-wishlist-search-form .form-wishlist-search .fieldset > .legend, .block.newsletter .label,
.review-date .review-details-label, .customer-review .product-details .rating-average-label,
.block-reorder .product-item .label, .action.skip:not(:focus),
.page-header .switcher .label, .page-footer .switcher .label,
.products-grid.wishlist .product-item .comment-box .label {
    border: 0 none;
    clip: rect(0px, 0px, 0px, 0px);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}


.abs-sidebar-totals .table-caption,
.multicheckout .block .methods-shipping .item-content .fieldset > .legend + br,
.multicheckout .hidden,
[class^='multishipping-'] .nav-sections,
[class^='multishipping-'] .nav-toggle,
.cart-summary .block .fieldset.estimate > .legend,
.cart-summary .block .fieldset.estimate > .legend + br,
.minicart-items .product-item-details .weee[data-label] .label,
.page-product-giftcard .fieldset.giftcard > .legend + br,
.order-details-items .order-items .order-gift-message .action.close,
.no-display,
.sidebar .subtitle,
.cart-totals .table-caption,
.opc-block-summary .table-totals .table-caption {
    display: none;
}

// Фикс меню - скорытие по .no-display
.menu > li > a.no-display {
    display: none;
}

// Подписи в секциях
.nav-sections-item-title {
    display: none;
}

// Обзоры, рейтинги

// Листинг
.review-items {
    list-style-type: none;
    margin: 0;
    line-height: 1.1;

    .review-item {
        border-bottom: 6px double $fg-blue-pastel;
        margin-bottom: 0.5rem;
    }

    .review-title {
        display: none;       
    }

    // Рейтинги
    .review-ratings {
        @include reviews-summary (24px, auto);

        .rating-summary {
            display: table;

            // Прячем все рейтинги кроме первого
            &:not(:first-child) {
                display: none;
            }

            // Зеленые звездочки
            .rating-result {
                >span {
                    &:before {
                        color: $fg-icon-green;
                    }
                }
            }
        }
    }

    // Текст отзыва
    .review-content {
        margin-top: 0.4rem;
        padding-top: 0.2rem;
        border-top: 1px solid $fg-blue-pastel;

        //font-family: $f-Neucha;
        font-family: $f-Marmelad;
        font-size: 1.2rem;
    }

    // Подпись
    .review-details {
        //font-family: $f-Neucha;
        font-family: $f-Marmelad;
        font-size: 1.4rem;
        font-weight: bold;
        color: $fg-blue2;

        margin-top: 0.5rem;

        .review-details-label {display: none;}
        .review-author, .review-date {
            display: inline;
            margin: 0;
        }
    }
}

// Форма добавления нового отзыва

.review-add {
    .block-title {display: none;}

    .block-content {
        .review-form{}
    }

    // Контейнер формы
    .review-fieldset {
        .review-legend {
            font-family: $f-Marmelad;
            color: $fg-h;
            font-size: 1.4rem;
        }
    }

    // Поле рейтинга
    .review-field-ratings {
        .review-field-rating {
            position: relative;
            label {
                cursor: pointer;
                display: block;
                position: absolute;
                margin: 0;

                span {display: none;}
            }

            $_star-size: 32px;
            .review-control-vote {
                margin-bottom: 3rem;
                overflow: hidden;
                &:before, label:before {
                    font-size: 32px;
                    height: 32px;
                    letter-spacing: 10px;
                    line-height: 32px;
                }

                &:before, label:before {
                    @include af-icon(); 
                    content: $fa-var-yingyang $fa-var-yingyang $fa-var-yingyang $fa-var-yingyang $fa-var-yingyang;

                    color: #c7c7c7;
                    display: block;
                    font-size: $_star-size;
                    height: $_star-size;
                    left: 0;
                    letter-spacing: 10px;
                    line-height: $_star-size;
                    position: absolute;
                    top: 0;
                    //width: 100%;
                    //z-index: 1;

                }

                input[type="radio"] {
                    border: 0 none;
                    clip: rect(0px, 0px, 0px, 0px);
                    height: 1px;
                    margin: -1px;
                    overflow: hidden;
                    padding: 0;
                    position: absolute;
                    width: 1px;
                    vertical-align: top;    
                    &:checked + label::before {
                        opacity: 1;
                    }
                }

                label::before { 
                    color: $fg-tertiary; //$fg-fivy;
                    opacity: 0;

                }
                label:hover:before { 
                    opacity: 1;

                }

                .rating-1 {
                    z-index: 6;
                    &:before {
                        content: $fa-var-yingyang;
                    }
                }
                .rating-2 {
                    z-index: 5;
                    &:before {
                        content: $fa-var-yingyang $fa-var-yingyang;
                    }
                }
                .rating-3 {
                    z-index: 4;
                    &:before {
                        content: $fa-var-yingyang $fa-var-yingyang $fa-var-yingyang;
                    }
                }
                .rating-4 {
                    z-index: 3;
                    &:before {
                        content: $fa-var-yingyang $fa-var-yingyang $fa-var-yingyang $fa-var-yingyang;
                    }
                }
                .rating-5 {
                    z-index: 2;
                    &:before {
                        content: $fa-var-yingyang $fa-var-yingyang $fa-var-yingyang $fa-var-yingyang $fa-var-yingyang;
                    }
                }

            }
        }
    }

    .actions-toolbar {

    }
}

// контент, который не нужен в ckeditor

$border-signature: 3px dotted $fg-h;
// Подпись
.content {
    .signature {   
        text-align: left;
        padding-top: 10px;
        padding-bottom: 5px;
        font-family: $f-OpenSansCon;  
        line-height: 1.2;


        .date {
            font-size: 1.3rem;
            color: black;
            border-top: $border-signature;
            margin-right: 6px;           
        }

        .author {
            font-size: 1.2rem;
            font-weight: bold;
            color: $fg-h;
            padding-left: 6px;
            padding-right: 3px;
        }

        .person {
            font-size: 1rem;
            font-weight: bold;
            color: lighten(black, 35);
            padding-left: 6px;
        }
    }
}

// Авторы
.content {
    .author-logo {
        padding-top: 15px;
        &.guardian {
            img {
                max-width: 150px;
            }
        }
    }
}

// Social
@include wg-social_share;

.social-container {       


    // Граница снизу
    &.border-bottom .social {
        border-bottom: $border-signature;
    }

    &#social-top, &#social-bottom  {}
}

// Подпись автора вместе с social 
.signature-container {
    .signature {
        float: left;
    }
    .social-container {       
        float: right;
    }       
}


// Error
.error-page {
    text-align: center;
    padding-top: 3rem;
    padding-bottom: 3rem;

    .headline {
        border-bottom: 0;
        font-size: 5rem;
    }

    h2, h3 {
        box-shadow: none;
    }

}


// Новости
.news-index {}
// Листинг новостей
.news-listing-container {
    @include wg-main_news-listing;

    margin-bottom: 1.5rem;
}
// Страница новостей
.news-view {

}

// Сайтмэп
.sitemap-view {}
.sitemap-container {
    @include wg-sitemap_sitemap;

}

// Яндекс-поиск
.ya_search-view {}
.ya_search-container {
    @include wg-ya_search;
}


// Словарь
.dictionary-index {
    @include wg-main_dic-listing;

    #social-bottom {
        margin-top: 2rem;
    }
}

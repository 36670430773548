
/* Прокрутка страницы  */

.scrollTop { 
    background: url("/images/_design/arrows.png") 0 0 no-repeat;
    background-position: 0 -58px;
    display:none; width:50px; height:50px; 
    position:fixed;
    bottom: 1rem; 
    left: calc(50% - 25px);
    z-index:3000; 
    opacity: 0.0; 
    transition: opacity 0.3s ease-in-out 0.3s;

    &:hover {
        opacity: 1 !important;
    }  
}

/*
#gotop-left {
    left: calc(50% - 490px - 60px);
}
#gotop-right {
    // left: calc(50% + 490px + 10px);
    left: 80%;
}
*/


// colours used throughout

$yii-blue: rgb(30, 104, 135);//#1E6887
$yii-light-blue: rgb(36, 123, 160);//#247BA0

$yii-red: rgb(255, 22, 84);//#FF1654
$yii-green: rgb(68, 179, 160);//#44B3A0
$yii-yellow: rgb(243, 255, 189);//#F3FFBD

// Собственные цвета сайта
$fg-gray: #525252; // серый в топ-страйп
$fg-almost-black: #110e05; // hsb(42, 68, 7); // Почти черный

$fg-viored-pale: #C08DA3;

$fg-red-pale: #d79292; //hsb(0, 47, 71); //
$fg-red: #b55f5f;
$fg-red-deep: #D13535;
$fg-red-deep2: #EC474E; // +++++++++ лого - красный
$fg-red-dark: #660000; // hsb(0, 100, 40);

$fg-red-pastel: #fce6e2; //++alert

$fg-yellow-pastel: #FBFBEE;

$fg-green: #bdef47; // hsb(78, 70, 94);
$fg-green-deep2: #30ab4a;  // +++++++++++++ лого зеленый
$fg-green-pale: #56AA66;

$fg-green-pastel: #E6F7EA;

$fg-brown: #a55103; //hsb(29, 98, 65); // Коричневый
$fg-orange-deep: #ff5501;
$fg-orange: #f9a759; //hsb(29, 64, 98); // Оранжевый

$fg-vio: #656b9e;
$fg-vio-deep2: #5d69b0; // ++++++++++++ лого фиолетовый
$fg-vio-pastel: #DCDDE9;
$fg-vio-pastel2: #d3d5ee;
$fg-vio-pastel3: #D0D1EB;
$fg-vio-dark: #32386D;
$fg-vio-dark2: #444EA8;

$fg-pink-pastel: #F7EEFB; //#FCF5FF
$fg-pink-pastel2: #FAF4FF;

$fg-blue3: #3F76AF; // добро пожаловать
$fg-blue2: #2c537c; // hsb(211, 64, 49); // синий
$fg-blue: #387487; // синий из логотипа
$fg-blue-deep2: #3ba7c9; // +++++++++++++++++ лого синий
$fg-blue-dark: #4d6b79; //hsb(199, 22, 39); // темно-синий
$fg-blue-dark-deep: #00697f; //hsb(190, 100, 50); // насыщенный синий
$fg-blue-water: #5de7fa;
$fg-blue-pastel: #E8EEF9;
$fg-blue-pastel2: darken($fg-blue-pastel, 3); //#dce5f6; // цвет выделенного пункта во вложенном верхнем меню
$fg-blue-pale: #97CFE2;

/// Мета переменные цветов

// Primary/secondary color в foundations
$fg-primary: $fg-vio; //
$fg-secondary: $fg-blue-deep2;
$fg-tertiary: $fg-green-deep2;
$fg-foury: $fg-vio-dark2;
$fg-fivy: $fg-red-deep2;


$bg-body: white;  //#e6eaec;
//$bg-header:  hsb(100, 27, 78);
$bg-header-top-bar-1: #8fa2b0; // градиент верх
$bg-header-top-bar-2: $bg-body; // градиент низ

$fg-h: $fg-secondary; //$fg-blue; // цвет заголовков
$fg-p: lighten(black, 20); // цвет текстов
$fg-a: $fg-blue2; // цвет анкоров
$fg-a-light: $fg-blue-dark-deep;

$fg-icon-def: $fg-vio-dark2;
$fg-icon-green: $fg-tertiary; //darken($yii-green, 5);
$fg-icon-red: $fg-red-deep2;
$fg-icon-blue: $fg-secondary;

$fg-input-border: $fg-vio-pastel2;
$fg-input: $fg-vio-dark2;
$fg-arrow: $fg-viored-pale; //

$bg-header-panel: $fg-vio-pastel;
$bg-header-panel-rgba: rgba($bg-header-panel, 0.6);
$bg-footer-panel-rgba: rgba($bg-header-panel, 0.2);

$bg-callout-alert: $fg-red-pastel; // бг коллаута


// Fonts

@import url(https://fonts.googleapis.com/css?family=Open+Sans+Condensed:300,300italic,700&subset=latin,cyrillic);
$f-OpenSansCon: "Open Sans Condensed", sans-serif; // 300 + Italic, 700

//@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400&subset=latin,cyrillic);
//$f-OpenSans: 'Open Sans', sans-serif; // 300, 400

@import url('https://fonts.googleapis.com/css?family=Cormorant+Infant|Marmelad|Neucha|Gabriela');

//$f-FiraSansCon: 'Fira Sans Condensed', sans-serif;
$f-Neucha: 'Neucha', cursive;
$f-Corm: 'Cormorant Infant', serif;
$f-Marmelad: 'Marmelad', sans-serif;
$f-Gabriela: 'Gabriela', serif;
//$f-Merri: 'Merriweather', serif;

// Alex
$font-path: '/static/frontend/Alex/tal/ru_RU/fonts/alex';
@font-face {
    font-family: 'alex';
    src:  url('#{$font-path}/alex.eot?k28u7m');
    src:  url('#{$font-path}/alex.eot?k28u7m#iefix') format('embedded-opentype'),
        url('#{$font-path}/alex.ttf?k28u7m') format('truetype'),
        url('#{$font-path}/alex.woff?k28u7m') format('woff'),
        url('#{$font-path}/alex.svg?k28u7m#alex') format('svg');
    font-weight: normal;
    font-style: normal;
}
@mixin af-icon() {
    @include fa-icon;
    font-family: 'alex';
}

$f-Alex: 'alex';
$fa-var-yingyang: "\e900";
$fa-var-yingyang-scetch: "\e901";
$fa-var-budda-lotos: "\e902";
$fa-var-wind: "\e903";
$fa-var-water: "\e904";

.af {@include af-icon;}
.af-yingyang:before {content: $fa-var-yingyang;}
.af-yingyang-scetch:before {content: $fa-var-yingyang-scetch;}
.af-budda-lotos:before {content: $fa-var-budda-lotos;}
.af-wind:before {content: $fa-var-wind;}
.af-water:before {content: $fa-var-water;}

// Alex
/*$font-path: '/static/frontend/Alex/tal/en_US/fonts';
@font-face {
    font-family: 'HuxleyRough';
    src:  url('#{$font-path}/a_HuxleyRough.ttf');
    font-weight: normal;
    font-style: normal;
}
$f-Hux: "HuxleyRough", sans-serif; // 300 + Italic, 700*/


// Transitions

$trans-color: color 0.2s ease-in 0.0s;
$trans-border: border-color 0.2s ease-in 0.0s;
$trans-bg-color: background-color 0.2s ease-in 0.0s;
$trans-bs-color: box-shadow 0.2s ease-in 0.0s;

// Boxshadow
$bs-menu: 0 0 6px 0 rgba(128, 128, 128, 0.4);


// Links

@mixin link-vio {
    color: $fg-vio;
    transition: $trans-color;

    &:visited {color: $fg-vio;}

    &:hover {
        color: $fg-vio-dark2;
    }
}

@mixin link-red {
    color: $fg-fivy;
    transition: $trans-color;

    &:visited {color: $fg-fivy;}

    &:hover {
        color: lighten($fg-fivy, 10);
    }
}


@mixin link-blue {
    color: $fg-blue3;
    transition: $trans-color;

    &:visited {color: $fg-blue3;}

    &:hover {
        color: lighten($fg-blue3, 15);
    }
}

@mixin link-green {
    color: $fg-tertiary;
    transition: $trans-color;

    &:visited {color: $fg-tertiary;}

    &:hover {
        color: lighten($fg-tertiary, 15);
    }
}

@mixin link-black-orange {
    background-color: $fg-almost-black;
    color: $fg-secondary;
}


// buttons
@mixin btn-vio {
    border: 1px solid $fg-vio-pastel2;
    transition: $trans-color, $trans-border;
    background: transparent;

    &:hover {
        border-color: darken($fg-vio-pastel2, 15);
    }
}

@mixin btn-alert {
    background: $fg-fivy;
    color: white;
    &:focus {
        background: $fg-fivy;
    }
    &:hover {
        background: lighten($fg-fivy, 10);
        a {
            color: white;
        }
    }
}

@mixin btn-submit {
    background: $fg-vio;
    &:focus {
        background: $fg-vio;
    }
    &:hover {
        background: lighten($fg-vio, 10);
        a {
            color: white;
        }
    }
}

// зеленая
@mixin btn-cart {
    background: $fg-tertiary;
    &:focus {
        background: $fg-tertiary;
    }
    &:hover {
        background: darken($fg-tertiary, 10);
        a {
            color: white;
        }
    }
}


@mixin icon-compare {
    color: $fg-a;
    transition: $trans-color;

    &:hover {
        color: lighten($fg-a, 15);
    }   
}

@mixin icon-remove {
    color: $fg-red;
    transition: $trans-color;

    &:hover {
        color: darken($fg-red, 5);
    }   
}

@mixin icon-heart {
    color: $fg-fivy;
    transition: $trans-color;

    &:hover {
        color: lighten($fg-fivy, 10);
    }   
}

@mixin icon-edit {
    color: $fg-tertiary;
    transition: $trans-color;

    &:hover {
        color: lighten($fg-tertiary, 5);
    }
}












/* Поделиться  */

@mixin wg-social_share { 

    // Блок соцсетей
    .social{
        display: inline-block;
        
        $social-size: 2.0rem;
        margin: 0;
        li{ 
            list-style-type: none;
            border-radius: 100%;
            //border: 3px solid white;
            float: left;
            margin: 0 0.1875rem 0.375rem 0;
            transition: transform 0.8s ease-in-out ;
            text-align: center;

            &:hover {
                //transform: rotate(360deg);
            }

            a{
                color: $white;
            }

            i{
                font-size: 17px;
                height: $social-size;
                line-height: $social-size;
                width: $social-size;

                &.fa-ok::before {content: "ok";}
            }

            $icon-darken: 10;
            &.facebook {
                background-color: #3067a3;
                &:hover, &:focus  {
                    background-color: darken(#3067a3, $icon-darken);
                }
            }
            &.twitter {
                background-color: #03b3ee;
                &:hover, &:focus  {
                    background-color: darken(#03b3ee, $icon-darken);
                }
            }
            &.share-mail {
                background-color: #767676;
                &:hover, &:focus  {
                    background-color: darken(#767676, $icon-darken);
                }
            }
            &.google {
                background-color: #e15440;
                &:hover, &:focus  {
                    background-color: darken(#e15440, $icon-darken);
                }
            }
            &.vk {
                background-color: #537599;
                &:hover, &:focus  {
                    background-color: darken(#537599, $icon-darken);
                }
            }
        }
    }
}

// 


// ya-search
@mixin wg-ya_search {

    padding-top: 1rem;
    padding-bottom: 1.5rem;

    .lookform {
        form {
            .input-group {
                margin-bottom: 0.5rem;

                .looktext {
                    border-radius: 10px 0 0 10px;                  
                    background-color: hsb(190, 4, 90);
                    padding-left: 0.8rem;
                    padding-right: 0.8rem;
                    color: $fg-blue-dark;
                }

            }            
        }

        // Сообщение об ошибке
        .msg {
            @include grid-column(12);

            color: $fg-blue;
            font-size: 0.8rem;
        }
    }

    .lookres {
        margin-top: 1.5rem;

        .search_notice {
            font-size: 0.8rem;
            padding: 0.3rem 0; 
        }

        // Результаты поиска
        .search_ol {
            margin-top: 0.5rem;

            .search_li {
                margin-bottom: 0.3rem;

                .search_title_a {                   
                    @include link-blue;
                }

                .search_passage {
                    font-size: 0.9rem;
                    line-height: 1.2;

                    .search_hlword {
                        font-weight: bold;
                        color: $fg-primary;
                    }
                }

                .search_url {
                    color: $fg-a;
                    font-size: 0.8rem;
                    margin-top: 0.3rem;
                }
            }
        }

        // Пейджер
        .search_page_top {
            border-top: 1px solid $fg-blue;
            font-size: 0.9rem;
            font-weight: bold;
            margin-top: 5px;
            padding-top: 5px;

            // Неактивный элемент
            .search_page_span {
                color: gray;
                padding: 0 5px;
            }
        }

        .search_page_bottom {
            margin-top: 5px;
            margin-bottom: 10px;

            .search_page_now {
                color: $fg-red-dark;
                font-size: 0.95rem;
                font-weight: bold;
                padding: 0 5px;
            }
        }

        // Общая для top и bottom
        .search_page_a {
            @include link-blue;
            font-size: 0.9rem;
            padding: 0 5px;
            text-decoration: underline;

        }
    }
}


// 


// menu-nodes
@mixin wg-nav_current-node {
    .container {
        padding: 0.5rem 0.7rem;
        border-top: 3px solid $fg-h;

        .menu {
            li {

                a {
                    @include link-blue;

                    padding: 0.3rem 0;
                    font-size: 0.95rem;
                    text-decoration: underline;
                }
            }
        }
    }    
}

// pult
@mixin wg-nav_pult {
    .container {
        .menu {
            li {                           
                padding: 0.5rem;
                font-size: 0.9rem;
                font-weight: normal;               
                a {
                    font-size: inherit;
                    padding: 0;
                }
            }

            // Вверх
            .up{
                padding-top: 20px;
                text-align: center;
                background: url("/images/_design/_nav/path/up.png") no-repeat center top;

                &:hover {
                    background-image: url("/images/_design/_nav/path/up-sel.png");
                }
                &.menu-text, &.menu-text:hover {
                    background-image: url("/images/_design/_nav/path/up-dis.png");
                }
            }

            // Вниз
            .down{
                padding-bottom: 20px;
                text-align: center;
                background: url("/images/_design/_nav/path/down.png") no-repeat center bottom;

                &:hover {
                    background-image: url("/images/_design/_nav/path/down-sel.png");
                }
                &.menu-text, &.menu-text:hover {
                    background-image: url("/images/_design/_nav/path/down-dis.png");
                }
            }

            // Влево
            .prev{
                height: 25px;
                line-height: 1.5;
                padding-top: 0; padding-bottom: 0;
                padding-right: 1.5rem;
                text-align: right;
                background: url("/images/_design/_nav/path/previous.png") no-repeat right center;
                border-right: 1.5rem solid transparent;

                &:hover {
                    background-image: url("/images/_design/_nav/path/previous-sel.png");
                }
                &.menu-text, &.menu-text:hover {
                    background-image: url("/images/_design/_nav/path/previous-dis.png");
                }
                a {
                    line-height: inherit;
                }
            }

            // Вправо
            .next{
                height: 25px;
                line-height: 1.5;
                padding-top: 0; padding-bottom: 0;
                padding-left: 1.5rem;
                text-align: left;
                background: url("/images/_design/_nav/path/next.png") no-repeat left center;
                border-left: 1.5rem solid transparent;

                &:hover {
                    background-image: url("/images/_design/_nav/path/next-sel.png");
                }
                &.menu-text, &.menu-text:hover {
                    background-image: url("/images/_design/_nav/path/next-dis.png");
                }
                a {
                    line-height: inherit;
                }
            }
        }
    }    
}



/* РСЯ 
*/

$ya-c-title: #d13535;
$ya-c-title-hover: #E54848;
$ya-c-sitelinks: $fg-red;
$ya-c-sitelinks-hover: lighten($ya-c-sitelinks, 10);
$ya-c-contacts: #658597;
$ya-c-contacts-hover: lighten($ya-c-contacts, 10);

// Общее для всех блоков
.yad {
    // Перечислить все id блоков, без partial
    &#yad-bottom, &#yad-top, &#yad-left-1, &#yad-left-2, &#yad-left-3,
    &#yad-left-wide-1, &#yad-left-wide-2,
    {

        // Фон для блока после закрытия сообщения
        .yap-layout__item_abused {
            background: rgba(white, 0.44) !important;
        }

        // Отступ для кнопки закрытия блока
        .yap-layout__content {
            padding-right: 10px;
        }

        // Обводка
        .yap-layout__item {
            border: 0 !important;
        }

        // Плашка
        .yap-logo {
            .yap-logo-position {
                .yap-logo-block__text {
                    text-decoration: none !important;
                }
            }
        }

        // ++++++++  Цвета и размеры
        // Заголовок
        .yap-title-block {
            line-height: 12px !important;
        }
        .yap-title-block__text {
            color: $ya-c-title !important;
            font-size: 13px !important;

            span, .yap-title-block__text_inner {
                transition: $trans-color, border-bottom-color 0.2s ease-in-out 0.1s !important;
                b {
                    color: $ya-c-title !important;

                }

                &:hover {
                    color: darken($ya-c-title, 10) !important;
                    border-bottom-color: darken($ya-c-title, 10) !important;
                }
            }
        }

        // Текст
        .yap-body-text, .yap-body-text b {
            color: lighten($fg-p, 10) !important;
            font-size: 12px !important;
        }

        // Сайтлинки
        .yap-sitelink-text {
            color: $ya-c-sitelinks !important;
            font-size: 12px !important;        
            span, .yap-sitelinks__inner {
                display: block !important;
                line-height: 1.2 !important;

                border-bottom: 0 !important;
                transition: $trans-color;
                color: $ya-c-sitelinks !important;

                b {
                    color: $ya-c-sitelinks !important;

                }

                &:hover {
                    color: $ya-c-sitelinks-hover !important;                
                }
            }
        }

        // Контакты
        .yap-contacts__item {
            a, a yatag, .yap-contacts__item-text {
                color: $ya-c-contacts !important;
                font-size: 11px !important;
            }
            a, a yatag {
                transition: $trans-color, border-bottom-color 0.2s ease-in-out 0.1s !important;
                &:hover {
                    color: $ya-c-contacts-hover !important;
                }
            }
        }


    }
}


// ЯД - топ  
.yad#yad-top {
    // Выравнивание по центру как в .small-centered
    $logo_width: 300px; // Ширина отступа слева (на ширину лого)
    max-width: calc(100% - 300px);
    float: left;
    margin-left: auto;
    margin-right: auto;
    padding: 0 !important;

    @include breakpoint (1200px down) {
        //@include grid-column(6);
    }

    @include breakpoint (800px down) {
        //@include grid-column(7);
    }

    .yap-layout__wrapper {
        // подложка блока
        background: transparent !important;
    }

    $ya-top-width: 400px;
    table.yap-layout__items {       
        //max-width:  $ya-top-width !important;

        .yap-item-inner {
            padding-bottom: 6px !important;
            padding-top: 2px !important;
            padding-left: 0 !important;
        }
    }
    // Плашка
    .yap-logo {
        background: url("/images/_design/yad-ani-top.gif") !important;
        padding-right: 2px !important;
        padding-left: 5px !important;

        .yap-logo-position {
            font-size: 10px !important;
            .yap-logo-block__text {
                font-size: 10px !important;
            }
        }

        .yap-logo-arrow {
            background: none !important;
        }
    }

    // ++++++++  Цвета и размеры
    // Заголовок
    //$ya-c-orange: #ff9e43;
    .yap-title-block__text {
        //color: $ya-c-orange !important;
        font-size: 15px !important;

        span {
            b {
                //color: $ya-c-orange !important;
            }

            &:hover {
                //color: darken($ya-c-orange, 10) !important;
                border-bottom-color: $ya-c-title-hover !important;
            }
        }
    }

    // Текст
    .yap-text {
        margin-top: 2px !important;
    }
    .yap-body-text, .yap-body-text b {
        color: lighten($black, 10) !important;
        display: inline-block !important;
        line-height: 11px !important;
    }

    // Контакты
    $ya-c-top-contacts: white;
    $ya-c-top-contacts-hover: $fg-red;
    .yap-layout__contacts {
        margin-top: 0 !important;   
    }
    .yap-contacts__item {
        a, a yatag, .yap-contacts__item-text {
            color: $ya-c-top-contacts !important;
            font-size: 11px !important;
        }
        a, a yatag {
            transition: $trans-color, border-bottom-color 0.2s ease-in-out 0.1s !important;
            &:hover {
                color: $ya-c-top-contacts-hover !important;
            }
        }
    }

    // Выравнивание предупреждений
    .yap-layout__contacts {
        float: left !important;   
    }
    .yap-layout__warning {
        float: left !important; 
        margin-left: 10px;
    }

}

// ЯД - над футером 
#yad-bottom {
    border-top: 1px solid black !important;


    @mixin ya-fixed {
        height: auto !important;
        min-height: 120px !important;
        width: 100% !important;
        overflow: visible !important;
    }

    // Делаем блок адаптивным
    [class^="yap-yandex_ad_bottom"], // старый формат
    [class^="yap-R-A-125123"] // новый формат
    { 
        @include ya-fixed;
        .yap-layout_block_fixed {
            @include ya-fixed;
            .yap-layout__wrapper {
                @include ya-fixed;
            }
        }
    }
    // Плашка
    .yap-logo {
        background: url("/images/_design/yad-ani-bottom.gif") !important;
    }
}


// ЯД - слева 
.yad#yad-left-1, .yad#yad-left-2, .yad#yad-left-3,
.yad#yad-left-wide-1, .yad#yad-left-wide-2,
{
    margin: 10px 0; 
    border-left: 1px dashed red;

    table.yap-layout__items {       
        .yap-item-inner {
            padding: 2px 5px 6px 5px !important;
        }
    }
    // Плашка
    .yap-logo {
        background: url("/images/_design/yad-ani-content.gif") !important;
        padding-right: 2px;

        .yap-logo-position {
            font-size: 12px !important;
            .yap-logo-block__text {
                font-size: 12px !important;
            }
        }

        .yap-logo-arrow {
            background: none !important;
        }
    }

    // ++++++++  Цвета и размеры
    // Заголовок
    .yap-layout__title {
        margin-bottom: 4px;
    }
    .yap-title-block__text {
        font-size: 11px !important;
    }

    // Текст
    .yap-text {
        margin-top: 4px !important;
    }
    .yap-body-text, .yap-body-text b {
        display: inline-block !important;
        line-height: 11px !important;
    }

    // Сайтлинки
    .yap-layout__sitelinks {
        margin-top: 4px !important;
    }
    .yap-sitelink-text {
        display: block !important;
        margin: 0 !important;   
        font-size: 11px !important;   
    }

    // Контакты
    .yap-layout__contacts {
        margin-top: 4px !important;  
        .yap-contacts{
            line-height: 9px !important;
        }
    }
    .yap-contacts__item {
        line-height: 9px !important;
        a, a yatag, .yap-contacts__item-text {
            font-size: 10px !important;
        }
    }
}

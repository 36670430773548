// Menu block

.page-header {   
    // Меню - в menu.scss
    .sections.nav-sections {}
}


.nav-sections {
    // .row
    .nav-sections-items {
        overflow:visible;
        // Контейнер nav.navigation
        .nav-sections-item-content {

        }
    }

}

.navigation {    
    background: rgba(#F4EEF5, 0.65);
    border-radius: 10px 10px 0 0;
    box-shadow: 0 -5px 15px -10px $fg-red-pale;


    max-width: 620px; //1200px;
    margin: 0 auto; 
    clear: both;
    white-space: nowrap;



    .dropdown.menu {
        // Общее
        li { 
            a {
                padding-top: 0.5rem; padding-bottom: 0.5rem;
            }
            a, span {
                font-family: $f-Gabriela;
                font-size: 0.9rem;
                color: $fg-blue2;
            }

        }
        // Активный элемент
        .active > a {
            background: transparent;
        } 

        // Верхняя строка ряд
        >li {


            // 2018-12-08 - Новинки - сдвиг первого элемента
            &.first {
                margin-left: -85px;
                display: inline-block;
                float: left;   

                > a {
                    padding: 0.5rem 0.5rem;
                }
            }
        } 
    }

    // Вложенные меню
    @include wg-foundation_menu-submenu;
    .submenu {
        overflow: visible;
        //display: block;

        >li:not(:first-child) {
            >.submenu {
                border-radius: 0.8rem;
                border-width: 0.2rem; //0.5rem;
            }   
        }
    }
    // Верхняя граница для подменю второго уровня
}


// Sidebar

#columns.row > .sidebar-column {
    //padding-top: 1rem;
}

.sidebar-column {  
    // name="div.sidebar.main"
    .sidebar.sidebar-main{}
    // name="div.sidebar.additional"
    .sidebar.sidebar-additional{
        margin-top: 1rem;
    }

    // Общий стиль аккордеона
    .menu {

        // Линк
        li {
            &:not(:last-child) {
                border-bottom: 2px solid $fg-vio-pastel2; 
            }
            >a, >.block-title {
                font-family: $f-Marmelad; 

                transition: $trans-bg-color;
            } 
            // Элемент списка как заголовок
            &.title {
                background: $fg-vio-pastel2;
                //font-weight: bold;
            }

        }       
        // Верхний уровень
        &:not(.nested):not(.dropdown) {
            border: 6px solid $fg-vio-pastel2; 
            border-top-style: double;
            border-radius: 15px;
            overflow: hidden;
            background: white;

            > li {                
                >a {
                    text-align: center;

                }
                &[aria-expanded="true"] {
                    >a {
                        background-color: $fg-vio-pastel;
                    }
                }
            }
        }
        // Вложенное меню
        .nested {
            li {
                >a {
                    font-size: 0.9rem;
                    padding: 0.5rem;
                    width: 100%;
                    .count {
                        display: inline;
                        float: right;
                    }
                    &:hover {
                        background-color: $fg-blue-pastel;
                    }
                }
            }
        }
    } // .menu

}

$fg-block-filter: $fg-blue-pale; // $fg-blue-deep2;
// $block \Magento\LayeredNavigation\Block\Navigation
.sidebar > .block {
    // Заголовок блока
    .filter-title {display: none}
    .filter-subtitle {display: none}

    // Аккордеон
    .block-content.filter-content{
        ul.filter-options.vertical.menu {}

        // Заголовок верхний уровень
        .filter-options-title {
            color: $fg-vio-dark2;
        }
    }

    // Отфильтрованная навигация
    .filter-current, &.block-compare, &.block-wishlist, &.account-nav {
        font-family: $f-Marmelad;   
        border: 6px solid $fg-block-filter; 
        border-top-style: double;
        border-radius: 15px 15px 0 0;
        border-bottom: 0;   

        background: white;

        .items, .product-items {
            .item, .product-item {
                padding: 0.2rem 0.5rem;
            }
        }
    }

    // Заголовок
    .block-content .block-subtitle, .block-title:not(.no-display) {
        color: $fg-a;
        text-align: center;
        width: 100%;
        display: block;    
        padding: 0.2rem 0.8rem;
        border-bottom: 2px solid $fg-block-filter; 
    }
    // Заголовок с фоном
    .block-title.background {
        background-color: $fg-block-filter;
    }
    // Заголовок как элемент списка без бордера внизу
    /*li > .block-title {
        border-bottom: 0;
    }  */


    // Товары не выбраны
    >div.empty, >.block-content >div.empty {
        color: $fg-vio;
        font-size: 0.8rem;
        padding: 0.3rem;
        text-align: center;
    }

    .block-content {
        // Список - общее для фильтров и результатов фильтров
        .items, .product-items {
            list-style-type: none;
            margin-left: 0;
            margin-bottom: 0;
            line-height: 1.6rem;

            // li
            .item:not(.is-accordion-submenu-item):not(.column-block), .product-item:not(.column-block) {   
                &:not(:last-child) {
                    border-bottom: 2px solid $fg-block-filter; 
                }
            }
            .item, .product-item {   


                >* {
                    display: inline-block;
                    vertical-align: middle;
                }

                .filter-name, .product-item-name {
                    color: $fg-a;
                    line-height: 1;
                    padding-left: 1.5rem;

                    span {
                        display: inline;
                    }
                    // Название фильтра
                    .filter-label {
                        font-weight: bold;
                    }
                    .filter-value {

                    }
                }


                // Кнопки
                >.action {
                    position: absolute;
                    padding-top: 0.1rem;
                    // Удалить
                    &.remove, &.delete {
                        @include icon-remove;
                        //i {color: $fg-icon-red;}
                    }
                    &.previous {

                    }
                }

            }

            // навигация
            .nav.item {
                transition: $trans-bg-color;
                &.active {                    
                    color: $fg-red;
                }
                &:not(.active):hover {
                    background-color: $fg-block-filter;
                    a {
                        color: $fg-a;
                    }
                }
            }


        }

        // Кнопки отфильт. нав.
        .filter-actions, .actions-toolbar {
            .filter-clear, .action.compare, .action.clear, .action.details {
                font-family: $f-Marmelad; 
                font-size: 1rem;
                transition: $trans-bg-color;
                background-color: $fg-block-filter;
                width: 100%;
                border-radius: 0 0 15px 15px;              

                &:hover {
                    background-color: lighten($fg-block-filter, 5);
                }
            }
        }
    }


}


// Нижний сайдбар
.sidebar-additional{}

.block {
    .block-title {
        // Текст
        .title {}
        // Кол-во
        .qty {

        }
    }
    .block-content {}
}


// Сравнение товаров
.sidebar-additional > .block-compare.block ,
.sidebar-additional > .block-wishlist.block,
.sidebar-main > .account-nav.block,
{
    margin-bottom: 1rem;
    border-radius: 15px;
    border-bottom: 6px double $fg-block-filter;
    overflow: hidden;
    background: white;
    .block-title {

    }
    .block-content {
        // ol
        .product-items {
            // li
            .product-item {
                .product-item-name {
                    a {}
                }

            }
        }
        // Кнопки сравнить / очистить
        .actions-toolbar {
            .action.button {
                border-radius: 0;
                margin: 0;
            }

            .button-group {
                margin: 0;
                >.action.button {
                    width: 50%;
                }
            }
            .action.compare {}
            .action.clear {
                color: white;
            }
        }

    }
}


// Список желаний ++++++++++++++++++++++++++++++++
.block-wishlist.block {
    .product-items {
        @include minicart-product-item;
    }
}

.sidebar-additional > .block-wishlist.block {
    .product-items > li.product-item {
        .product-item-details {
            .product-item-name {
                padding-left: 0;
                text-align: right;
                margin-bottom: 0.6rem;
                .product-item-link {
                    margin-bottom: 0;
                }
            }
        }

        // Картинка
        .product-image-container {
            .product-image-photo {
                max-width: 60px;
            }
        }

        // Цена
        div.price-box { margin-bottom: 0}

        .price-as-configured {
            margin-bottom: 0;  
            line-height: 1.1;
            display: table;
            .price-container {
                display: table-cell;
            }

            .price-wrapper > .price {
                font-weight: normal; 
            }
        }

        // Добавить в корзину
        .product-item-actions {
            display: inline-block;
            //vertical-align: middle;

            >* {
                display: inline-block;
                //vertical-align: middle;
            }
            >*>* {
                display: inline-block;
                vertical-align: top;
            }

            button.action {
                cursor: pointer;            
                &.tocart {
                    @include icon-edit;
                }             
            }
            .action.delete {
                @include icon-remove;
            }


        }
    } // .product-items
}


// Sidemenu - меню категорий в сайдбаре +++++++++++++++++++++++++++++++++++

.widget.block-categories.sidemenu
{
    margin-top: 1rem;
    margin-bottom: 2rem;
    font-family: $f-Marmelad;   

    .block-title {
        @include content-header;
    }

    ul {
        list-style-type: none;
        margin-left: 0;

        .is-accordion-submenu-parent > .group-link {
            float: right;
            &:after { 
                right: 0;
                margin-top: 3px;
            }
        }

        a:not(.group-link) {
            display: block;
            border-bottom: 1px dotted $fg-blue-deep2;
        }

        .wrapper {

        }

        /*.count {
            display: inline-block;
            float: right;
            color: $fg-icon-green;
        }*/

        li {
            margin: 7px 0 5px 9px; // Отступы повыше
        }
    }
} 

// Недавно просмотренные товары ++++++++++++++++++++++++++++++++++++++++++++++++++++++
.sidebar {
    > .widget.block-viewed-products-list {

        .block-title {
            font-family: $f-Marmelad;           
        }

        .product-items {
            margin: 0;
            padding-top: 0.3rem;
            .product-item {
                padding: 0 0.3rem;
                margin-bottom: 0.5rem;
                .product-item-info {
                    display: block;

                    .product-item-name, .actions-primary {
                        padding: 0;
                    }
                    .product-item-name {
                        height: 45px;
                    }

                }
            }
        }
    }
}

// 2020-02-01 Вертикальное дропдаун-меню ++++++++++++++++++++++++++++++++++++++++++++++++++++++
.DropdownMenu {

    @include wg-foundation_menu-submenu($class: '.DropdownMenu-ItemsNested');
    &-ItemsNested {
        border-radius: 0.6rem !important;
        border-top-width: 0.2rem !important;
    }

    &-Item {  
        margin: 0 !important;     
        &.is-dropdown-submenu-item {
            .DropdownMenu-Link {
                padding: 0.4rem 0.6rem !important;
            }
        }

        &:not(:last-child){
            border-bottom: 1px dotted $fg-blue-deep2 !important;
        }  

        &_parent {
            // стрелка
            a::after {
                margin-top: -5px !important;
                right: 0px !important;
            }
        }

        &.level0:nth-child(even) {
            background-color: lighten($fg-pink-pastel, 2);
        }
    }

    &-Link {
        border: none !important;
        padding: 0.4rem 0.2rem !important;
    }
}


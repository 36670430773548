
// Корзина +++++++++++++++++++++++++++++++++++++++++++

.cart-container {
    margin-top: 0.3rem; // отступ от сообщений выше
}

.checkout-cart-index {
    .cart-empty {
        @include field-note;
        text-align: center;
    }


    // Стики корзины
    .stick-spacer-cart-wrapper.column {
        padding-left: 0;        
        .stick-spacer-cart-wrapper {
            padding-right: 0.5rem;
        }
    }

    // Итоги корзины
    .cart-summary {
        @include order-font;

        &.is_stuck {
            background: white;
            z-index: 10;
            top: 180px !important; // отступ из-за бага sticky kit
        }

        .block.shipping {
            .title {
                display: none;
            }
        }

        // Итоги корзины
        .cart-totals {
            .table.totals {
                font-size: 1.4rem;
                text-align: right;

                // строка с налогами
                .totals-tax {
                    display: none;
                }

                // Доставка
                .totals.shipping {
                    .label {
                        display: none;
                    }
                }

                .mark {
                    font-size: 1.1rem;
                    text-align: right;
                }

                // знак валюты
                .amount {
                    i{
                        font-size: 0.9rem;
                    }
                }
            }
        }

        // Кнопки под итогами корзины
        .checkout-methods-items {
            list-style-type: none;
            margin: 0;
            text-align: center;

            .action.checkout {
                @include btn-cart;
                font-size: 1.1rem;
            }
        }
    }

    // Корзина - таблица
    .cart-form-container {
        //table
        .items.table {
            @include order-font;
            margin-bottom: 1rem;

            @include table-transparent;

            caption {
                display: none;
            }

            .item-info {
                td {
                    padding-bottom: 0;
                }
            }

            .col.item {
                .product-image-photo {
                    max-width: 120px;
                }
                .product-item-details {
                    font-family: $f-Gabriela;
                    display: inline-block;
                    margin-left: 1rem;
                    font-size: 1.2rem;

                    strong {
                        font-weight: normal;
                    }

                    @include breakpoint(800px){
                        width: calc(100% - 150px);
                    }
                }
            }
            .col.qty {
                text-align: center;
                // 2019-12-22 - фикс бага со съезжающей кнопкой
                min-width: 100px;

                .field.qty {
                    margin-bottom: 0;
                }
                input {                                  
                    @include product-qty-input;

                    background: transparent; // чтобы readonly не был серым
                }

                // Кнопки количества
                .QtyButton {
                    font-size: 1.2rem;
                }

            }
            td.col.price, td.col.subtotal {
                font-size: 1.4rem;
                text-align: right;

                i{
                    font-size: 1rem;
                }
            }

            // Ряд с кнопками
            .item-actions {             
                background: transparent;

                td {
                    padding-top: 0;
                    border-bottom: 1px solid $fg-blue-pastel;
                    text-align: right;
                }

                .action {
                    font-size: 24px;
                    margin-left: 0.3rem;
                }

                .action-towishlist {
                    @include icon-heart;
                }
                .action-edit {                   
                    @include icon-edit;
                    display: none;
                }             
                .action-delete {
                    @include icon-remove;
                }

            }
        }
    }


    // Нижние кнопки
    .cart.main.actions {
        text-align: center;
        margin-bottom: 1rem;
    }

}



// ++++ Оформление заказа
.checkout-index-index {}

.checkout-container {
    // Стадии оформления заказа
    .opc-progress-bar {
        list-style-type: 0;
        position: relative;
        border-radius: 0.4rem;

        .opc-progress-bar-item {
            display: table-cell;
            veritcal-align: middle;
            padding: 0.5rem;
            padding-left: 1.5rem;  // Отступ для стрелки

            font-family: $f-Gabriela;
            font-size: 1.0rem;

            color: $fg-vio;
            background-color: $fg-vio-pastel2;

            &:after {
                border-bottom: 1.1rem solid transparent;
                border-left: 1rem solid $fg-vio-pastel2;
                border-top: 1.1rem solid transparent;
                content: "";
                display: block;
                height: 0;
                position: absolute;
                top: 0;
                width: 0;

                margin-left: -1.5rem;
            }

            &._active {
                background-color: $fg-vio;
                color: white;

                &:first-child:after {
                    display: none;
                }

                +div:after { 
                    border-left-color: $fg-vio;
                }
            }
        }

    } // .opc-progress-bar


    // Шаги
    .opc-wrapper {
        margin-top: 1.5rem;
    }

    // ul
    .opc {
        list-style-type: none;
        margin: 0;

        @include order-font;  

        input, select {
            font-family: $body-font-family;
        }


        .step-title {
            @include content-header;
            @include order-title;
            font-weight: normal;
        }

        .step-content {
            @include order-content;
            margin-bottom: 1.5rem;
        }

        .action:not(.button) {
            @include text-actions-toolbar;
            @include link-vio;
            margin-right: 1rem;
            cursor: pointer;
        }
    }
} // .checkout-container  


// ------------------------------
.checkout-container {
    // li - адреса доставки
    .checkout-shipping-address {
        .field.addresses {
            margin-bottom: 0;
        }
        // Список адресов
        .shipping-address-items {
            margin-top: 1rem;
            margin-left: -0.5rem;
            margin-right: -0.5rem;
            // Выбранный адрес
            .column-block {
                position: relative; // для иконки
                padding: 0 0.5rem;
                margin-bottom: 1rem;
            }
            .shipping-address-item {
                border-radius: 0.6rem;
                padding: 0.5rem;
                border: 1px solid $fg-vio-pastel2;
            }
            .selected-item {
                background-color: $fg-pink-pastel;

                // Кнопка выбрать
                .action-select-shipping-item {
                    visibility: hidden;
                }
                // Иконка
                &:after {
                    @include fa-icon;
                    content: $fa-var-check;
                    color: $fg-icon-red;
                    position: absolute;
                    right: 1.5rem;
                    top: 0.5rem;
                }

            }

        } // ..shipping-address-items


        // Новый адрес - заказ под гостем
        .step-content {
            // Адрес электронной почты
            .form-login {
                .actions-toolbar {
                    .action.login {
                        @include button;
                        @include btn-submit;
                    }
                    .action.remind {
                        margin-right: 0;

                    }
                }
            }
            // Адрес доставки
            .form-shipping-address {
                // 2020-04-17 Скрываем регион
                [name="shippingAddress.region"] {
                    display: none;
                }
                // 20204-04-17 Визуальное отделение полей адреса
                [name="shippingAddress.custom_attributes.city_code"] {
                    border-top: 1px solid $fg-vio-pastel2;
                    padding-top: 1rem;
                    margin-top: 1rem;
                }
            }
        }


    } // .checkout-shipping-address
}  // .checkout-container


.checkout-container {
    // li - Методы доставки
    .checkout-shipping-method {
        .shipping-policy-block {
            display: none;
        }

        .table-checkout-shipping-method {
            margin-bottom: 1rem;
            thead {
                display: none;
            }

            // Тип способа доставки
            .col-carrier {
                display: none;
            }
            // radio
            .col-method input {
                margin-bottom: 0;
            }
            // Цена
            .col-price {
                text-align: right;

                // Методы доставки с нулевой ценой
                &[data-price="0"] {
                    .price {
                        //display: none;
                    }
                }

                i {
                    font-size: 0.9rem;
                }
            }
        }

        .message.notice {
            margin-bottom: 1rem;
        }
    } // .checkout-shipping-method

    // С условиями работы согласен
    .my_chk_container {
        ul {
            list-style-type: none;
            li {
                label {
                    font-size: 1.2rem;
                    a {
                        @include link-vio;
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}  // .checkout-container


.checkout-container {

    // li - Методы оплаты
    .checkout-payment-method {
        margin-left: auto;
        margin-right: auto;
        //max-width: 300px;

        // в порядке иерархии
        .form.payments {}
        .fieldset {}

        // контейнер методов оплаты и кнопки подтверждения заказа
        .opc-payment {


            @include breakpoint(small only) {
                padding-top: 1rem;
            }

            .payment-methods {}
            .payment-group {
                // Заголовок
                .step-title {
                    display: none;
                }

                .payment-method {

                    .payment-method-title .label {
                        margin: 0;
                        text-align: center;
                    }

                    // Контейнер кнопки оплаты
                    .payment-method-content {
                        // Прячем платежный адрес и платежные соглашения
                        .payment-method-billing-address,
                        .checkout-agreements-block{
                            display: none;
                        }

                        // Кнопка  Оформить заказ
                        .actions-toolbar {
                            margin-top: 1.0rem;
                            margin-bottom: 0.5rem;

                            button {
                                @include button;
                                @include btn-cart;
                                font-size: 1.1rem;
                                margin: 0;
                            }
                        }
                    } // .payment-method-content
                } //.payment-method
            } // .payment-group
        } // .opc-payment

    } // .checkout-payment-method


    .checkout-payment-method {

        // Контейнер доп. кнопока
        .checkout-payment-aftefMethods {

            .payment-option {
                float: left;       
                width: 100%;

                margin-bottom: 0.5rem;


                // Кнопка
                &-title {
                    margin-bottom: 0;               

                    .action {
                        margin: 0 auto !important;
                    }
                }
                // Контейнер опции
                &-content {
                    max-width: 200px;
                    padding: 0.5rem 0;

                    // Применить скидку
                    .form-discount {
                        .field {
                            margin: 0;
                            input {border-radius: 8px 8px 0 0;}
                        }
                        .label {display: none}
                        // Кнопка применить
                        .action-apply {
                            margin: 0;
                            width: 100%;
                            .button {
                                width: 100%;
                                font-size: 0.8rem;
                                margin: 0;
                                border-radius: 0 0 8px 8px;
                            }
                        }
                    }
                }


            } // .payment-option
        } //.checkout-payment-aftefMethods

    } // .checkout-payment-method



    // Кнопки внизу ??
    .actions-toolbar {
        text-align: center;
        .action.continue {
            @include btn-cart;
            font-size: 1.1rem;
        }
    }

} // .checkout-container


// Итог заказа на шаге billing-step
.CheckoutSummary {
    &-Block {
        &_items {
            margin-top: 0.5rem;
        }
    }
    &-Title {
        margin: 0 0.5rem !important;
    }
    &-Content {
        font-size: 1.2rem;
        line-height: 1.4;

        &_pvz {
            margin-top: 0.5rem;
        }

        b {
            font-size: 0.9rem;
        }

        .button {
            margin-left: 1rem; // кнопкая яндекс.карт
        }
    }

    // Таблица
    &-Items {
    }
    &-Item {
        // заголовки
        &_headers {
            font-weight: bold;
            font-size: 0.9rem;
            padding-bottom: 0.2rem;
            border-bottom: 1px solid;
        }
    }
    &-Cell {
        padding: 0 0.5rem;

        i {
            font-size: 0.7em; // знак валюты
            font-style: normal;
        }

        &_serial {
            width: 2rem;        
            text-align: center;
            @include breakpoint(medium up) {}
        }
        &_name {     
            text-align: left;
            @include breakpoint(medium up) {
                width: calc(100% - 14rem);    
            }
            @include breakpoint(small only) {
                width: calc(100% - 2rem);
            }
        }
        &_price, &_qty, &_total {
            float: right;
            text-align: right;
            @include breakpoint(medium up) {
                width: 4rem;    
            }
        }
        &_qty {
            text-align: center;  
        }

        // Итоги
        &_totalsValue {}
        &_totalsValueRed {
            color: red;
        }
        &_totalsName {
            text-align: right;
            @include breakpoint(medium up) {
                width: calc(100% - 4rem);    
            }
        }
    }
    &-Totals {
        font-weight: bold;
        font-size: 0.9em;
        border-top: 2px solid;
    }
}


// Модальное окно в методах доставки - новый адрес
.modal-content {
    .form-shipping-address {
        // Скрываем страну
        [name="shippingAddress.country_id"] {
            display: none;
        }
    }
}


// 2020-04-14
// Авокомплит города

.AutoComplete {
    $clr-full-name: lighten($fg-vio,14); // Размытый цвет полного названия города

    position: relative;
    margin-bottom: 0;

    &-Input {
        position: relative;
        background: none !important;
        margin-bottom: 0;
    }

    &-InputFull {
        position: absolute;
        background: $white !important;
        margin-bottom: 0;

        color: $clr-full-name;
    }


    // Список опций
    .ui-autocomplete {
        max-height: 300px;
        overflow-y: scroll;
        background: $white;
        box-shadow: $bs-menu;

        z-index: 101; // чтобы перекрыть лоадер мадженто
        //display: initial !important;
    }
    // Элемент списка
    &-Item {
        padding: 0.3rem 0.2rem;
        // обнуляем дефеолтный ститль jquery-ui
        list-style-image: none !important;

        font-family: $body-font-family;
        font-size: 0.9rem;

        &:hover {
            background: $fg-blue-pastel2 !important;
        }

        &:first-child {
            background: lighten($fg-vio-pastel3, 1) !important;
        }
    }

    // Акшн 
    &-Action {

        // Наведение мыши
        &.ui-state-focus {
            margin: 0 !important;
        }
    }
    &-Label {
        color: $clr-full-name;
        // Выделение
        > span {
            color: $fg-vio-dark2;
        }
    }


    // Кнопки городов
    &-Cities {
        margin-top: 0.5rem;
        margin-bottom: 0.3rem;
    }

    &-City {
        @include btn-cart;
        font-size: 0.9rem;
        border-radius: 6px;
        padding: 0.3rem 0.5rem;
        margin-bottom: 0.2rem;
    }

}


// Виджет выбора пункта доставки
.PvzPicker {

    &_hidden {
        background: whitesmoke;
    }

    &-DtContainer {
        paddint-top: 1rem;
        margin-bottom: 2rem;
    }

    // Таблица datatables
    &-Dt {
        font-size: 1.1rem;

        thead {
            font-size: 0.8em;
        }
    }

    &-Action {
        &_collapse {
            border-radius: 6px;
            padding: 0.3rem 0.5rem;

            margin: 0.8rem 0 0.5rem;
        }
    }

    @mixin dt_buttons {
        font-size: 0.8rem;
        border-radius: 4px;
        padding: 0.2rem 0.3rem;
        margin-bottom: 0.0rem;
    }

    // Элементы таблицы
    &-MapButton {
        //@include btn-cart;
        //@include btn-alert;
        @include btn-submit;
        @include dt_buttons;
        text-align: left;
    }
    &-PickButton {
        @include btn-cart;
        //@include btn-alert;
        @include dt_buttons;
        margin-right: 0.5rem;
    }

    // классы dataTables
    .dataTables {
        // Сама таблица

        // Контейнер
        &_wrapper {}

        @mixin dt_inputs {
            font-size: 0.9rem;
            padding: 0.2rem;
            height: auto; 
            margin-bottom: 0.3rem;
        }
        // выбор pageSize
        &_length {
            select {
                @include dt_inputs;
                padding-right: 1.5rem;
                width: auto;
            }
        }
        // поиск
        &_filter {
            width: 50%;

            >label {
                line-height: 1.5;
            }
            input {
                float: right;
                width: calc(100% - 70px);
                @include dt_inputs;
            }
        }

        // инфо о размере выборки
        &_info {
            font-family: $f-Gabriela;
            @include dt_inputs;
        }

        // пагинатор
        &_paginate {
            font-family: $f-Gabriela;
            @include dt_inputs;

            .paginate_button {
                border-radius: 50% !important;
                padding: 0.1rem 0.3rem !important;

                &.previous, &.next {
                    border-radius: 2px !important;
                }
            }
        }

    } // .dataTables

    // Блок выбранного ПВЗ
    &-PickTitle {
        font-size: 0.9em;
        margin-bottom: 0.5rem !important;
    }

    &-PickContainer {
        //padding-top: 1rem;
    }
    &-PickItem {
        font-weight: bold;
        font-size: 0.9rem;
        span {
            font-weight: normal;
            font-size: 1.1rem;
            padding-left: 0.5rem;
        }

        .PvzPicker-MapButton {
            font-size: 0.9rem;
            margin-top: -0.2rem;
            margin-left: 0.5rem;
        }
    }
}


// Заказ успешно оформлен

.checkout-success.content-wrapper {
    max-width: 450px;
    margin: 0.5rem auto 1rem;
    text-align: center;

    border-radius: 0.6rem;
    padding: 0.5rem;

    @include breakpoint(medium up) {
        border: 1px solid $fg-vio-pastel2;
    }

    .PaymentNote {
        padding: 1rem 0 0.3rem;

        border-top: 1px solid $fg-vio-pastel2;
        font-size: 1.2em !important;
    }

    p {
        @include field-note;
    }

    h3 {
        text-align: center;
        .order-number {
            @include link-blue;
            text-decoration: none;           
        }
    }
    .actions-toolbar {
        margin: 1rem 0 0;
    }
}

.stick-spacer-cart {
    //float: right;
    .cart-summary {
        //left: inherit;
    }
}

// Регистрация для незарегистрированных при успешном оформлении заказа

.success-registration.content-wrapper {
    border-top: 1px solid $fg-input-border;
    text-align: center;

    p {
        @include field-note;
    }

}


// ----------------- страница оплаты PAW ---------------------------------------
.FormPaw {
    padding-top: 1rem;
    padding-bottom: 1rem;

    &-Order {
        margin-bottom: 2rem;
    }

    &-Record {
        margin: 0 auto;
    }

    &-RecordName {
        font-weight: normal;
    }   
    &-RecordValue {
        font-weight: bold;
    }

    &-Note {   
        margin: 1rem auto;
        text-align: center;
        
        border-radius: 0.6rem;
        padding: 1rem;

        @include breakpoint(medium up) {
            border: 1px solid $fg-vio-pastel2;
            max-width: 400px;
        }
        //border-top: 1px solid $fg-vio-pastel2;

        @include field-note;
        font-size: 1.2em !important;
    }


    // Форма оплаты Paw
    &-Form {
        text-align: center;
        margin-top: 1rem;
        margin-bottom: 2rem;
    }

    // Кнопки оплаты
    &-ButtonPay {
        @include button;
        @include btn-cart;
        font-size: 1.1rem;

        margin-top: 0.5rem;
    }
}









// Стили по умолчанию

// h1
.page-layout-1column {
    .page-title {
        text-align: center;
    }
}

// Header
@mixin content-header {
    font-family: $f-Gabriela;
    color: $fg-h;
    border-bottom: 1px solid $fg-blue-pastel;

    padding-left: 0.9rem; // для границы слева
    padding-bottom: 0.1rem;
    text-align: left;
    line-height: 1;

    font-weight: normal;
}
.content-header {
    @include content-header;
    &.no-border {
        border-bottom: 0;
    }
}

// Кнопка
.button {
    font-family: $f-Marmelad;
    color: white;

    &.alert, &.success {
        color: white;
    }
}

// Кнопки закрытия
.close-button.tiny {
    font-size: 1.3rem;
    right: 0.6rem;
    top: 0.4rem;
}

// Tooltip
.tooltip {
    border: 6px double ;
    text-align: center;
    &:before {
        border-color: transparent transparent $fg-tertiary;
    }
}

// Thumbnail
.thumbnail {
    transition: $trans-bs-color;
    &:hover {
        box-shadow: 0 0 6px 1px rgba($fg-input-border, 1);
    }
}

// Badge
.badge {
    border-radius: 1rem;
    font-family: $f-Marmelad;
    font-size: 1rem;
    padding: 0.2rem 0.7rem;
    padding-top: 0;

    background-color: $fg-green-pale;
}



// Дропдаун Меню
@mixin wg-foundation_menu-submenu ($class: '') {
    .submenu#{$class} {
        //border-style: double;
        border-style: solid;
        border-color: $fg-vio-pastel3; //$fg-primary; //$fg-secondary;
        border-width: 0.2rem; // 0.5rem;
        border-top-width: 0;
        background-color: $fg-blue-pastel;
        border-radius: 0 0 0.8rem 0.8rem;
        box-shadow: 0 0 6px 0 rgba(gray, 0.4);
        overflow: hidden;
        //min-width: 170px;
        li {
            >a i {
                vertical-align: middle;
                font-size: 0.95rem;
            }
            >a:hover {
                background-color: initial !important;
            }
            &:hover {
                background-color: darken($fg-blue-pastel, 3) !important;
            }
            
            
        }
    }
}

// Пейджер
@mixin wg-foundation_pagination {
    $bg: $fg-blue3; //-dark-deep;
    $bg-inactive: $fg-blue-pale; //$fg-blue; //-dark;
    .pagination {
        // Номерки
        font-family: $f-Gabriela;
        li >a {
            text-align: center;
            height: 35px;
            width: 35px;
            span {
                line-height: 26px;
                display: inline-block;
                vertical-align: middle;
            }
        }

        li:not([class^="pg-"]){
            >a{
                border-radius: 30px;
                border: 2px solid $bg;
                color: $bg;
                //font-size: 1.2rem;

                &:hover{
                    background: $bg;
                    color: white;
                }
            }
        }
        // В начало/конец
        li[class^="pg-"]{
            a{
                &:hover{
                    background: transparent;
                    //color: $bg-body;
                }
            }
        }
        // Текущий
        li.current{
            background: 0;
            padding: 0;
            >a{
                color: $bg-inactive;
                border-color: $bg-inactive;
                cursor: default;
                &:hover{
                    background: white;
                    color: $bg-inactive;
                }
            }
        }
        // Неактивные
        .disabled {
            color: $bg-inactive;
        }
    }
}


// Закладки
.tabs {
    border-radius: 1rem 1rem 0 0;
    .tabs-title {
        border-right: 1px solid $fg-input-border;
        a {
            font-family: $f-Marmelad;
            font-size: 0.85rem;
        }
    }
}

.tabs-content {
    border-radius: 0 0 1rem 1rem;
}

// Форма
// Исправление бага с лейблом
label.label {
    //cursor: inherit;
    white-space: normal;
    background: transparent;
    border-radius: 0;
    display: block;
    padding: 0;

    color: $form-label-color;
    font-size: $form-label-font-size;
    font-weight: $form-label-font-weight;
    line-height: 1.1;
}


label {
    font-family: $f-Gabriela;  
}
.field.required {
    >label::after {
        color: $fg-icon-red;
        content: "*";
        font-size: 1.2rem;
        margin: 0 0 0 5px;
    }
}

@mixin field-note {
    font-family: Gabriela;
    color: $fg-blue3;
}

// Примечание в форме
.field.note {
    @include field-note;
}

// Подсказки под полем, отступы
.field {
    margin-bottom: 0.5rem;
    .control {
        input, select {
            margin-bottom: 0;
        }
        
        // сообщения об ошибках
        div.mage-error, div.field-error {
            margin-top: 0.1rem;
            color: $fg-red;
            font-family: $f-Marmelad;
            font-size: 0.8rem;
        }
        // подсказка
        .field-tooltip {
            font-family: $f-Marmelad;
            font-size: 1.1rem;
            .field-tooltip-content{
                color: $fg-green-deep2;
            }
        }
        // примечание
        .note {
            font-family: $f-Marmelad;
            font-size: 1rem;
            color: $fg-secondary;
            display: block;
            line-height: 0.9;
            padding-top: 0.3rem;
        }
    }
}

.fieldset {
    border-radius: 1rem;
    legend {
        background: transparent;
        color: $fg-foury;
        font-family: $f-Marmelad;
        font-size: 0.8rem;
        & + br {
            display: none;
        }
    }
}

// Подписи к чекбоксам
.field {
    [type="checkbox"], [type="radio"] {
        & + label {
            display: inline-block;
        }
    }
    // чекбокс внутри лейбла
    >label {
        [type="checkbox"] {
            margin-bottom: 0;
        }
    }
}




@mixin inline {
    >*, >*>* {
        display: inline-block;
        vertical-align: middle;
    }
}


// Кнопки
.actions-toolbar.inline {
    @include inline;
}

// Actions Toolbar
@mixin form-actions-toolbar {
    @include inline; 
    margin-bottom: 1rem;
    .action {      
        &.primary, &.back, &.update, &.share, &.tocart {           
            font-family: $f-Marmelad;
            @include button (null, $fg-primary, scale-color($fg-primary, $lightness: -15%));
            margin-bottom: 0;
        }
    }
}

.block .form .actions-toolbar {
    @include form-actions-toolbar;
}

.block .form .fieldset .actions-toolbar { 
    margin-bottom: 0;
}

.page-layout-1column .block .form .actions-toolbar {
    text-align: center;
}

// Actions Toolbar - строчный
@mixin text-actions-toolbar {
    font-family: $f-Marmelad;
    font-size: 1rem;
    margin-top: 0.5rem;

    .action {
        @include link-vio;
        margin-right: 1rem;
    }
}



// Поля данных - заказы и т.п.

@mixin order-font {
    font-family: $f-OpenSansCon;
    color: $fg-gray;
}

@mixin order-title {
    display: block;
    font-size: 1.1rem;
    font-weight: bold;
    margin: 0.5rem 0 0.2rem 0; 
}
@mixin order-content {
    font-size: 1.2rem;
}

.record {
    @include order-font;
    @include order-content;
    display: table;
    .name, .value {
        display: table-cell;
    }
    .name {
        @include order-title;
        margin: 0;
        margin-bottom: 0.2rem;
        padding-right: 0.5rem;
    }
}

// Таблицы  ++++++++++++++++++

// Прозрачная таблица
@mixin table-transparent {
    tbody, tfoot {
        background-color: rgba(#fefefe, 0.5);
    }

    thead, tbody tr:nth-child(2n) {
        background-color: rgba(#f8f8f8, 0.5);
    }
}




// Капча ++++++++++++++++++++++++++++++++++++++++
.field.captcha {
    .nested {
        margin-top: 0.5rem;

        .captcha-img {
            margin-right: 0.5rem;
        }

        .captcha-reload {
            @include button;
            @include btn-cart;
        }
    }
}


// Подпись в статьях и описаниях
@mixin signature {
    .signature {
        margin-top: 1rem;                                        
        text-align: right;

        font-family: $f-Gabriela;
        font-size: 1.2rem;

        .line-1 {
            color: $fg-icon-red;
            font-weight: bold;
        }
        .line-2 {
            a {
                @include link-blue;
                text-decoration: none;
            }
        }
    }
}

@include signature;



// Футер
.page-footer {
    background: $bg-footer-panel-rgba;
    border-top: 2px solid $fg-vio-pastel3;

    //background-color: white;
    padding: 1rem 0;
    padding-top: 0;

    >.row {
        >.column {
            padding-top: 1rem;
        }       
    } 

    .column-left {
        border-right: 2px solid #d0d1eb;
    }

    // links   
    // .menu.vertical
    .FooterLinks {
        &_1 {}
        &_2 {}
    }

    .FooterLinks-Items {
        font-family: $f-Marmelad;
        font-size: 1rem;

        &_1 {}
        &_2 {}

        li {
            text-align: right;

            @include breakpoint (small down) {
                text-align: center;
            }

            a, span {
                display: block;
                padding: 0.3rem 1rem;
                padding-right: 0;
                line-height: 1;
                font-size: 1.1rem;

                @include breakpoint (small down) {
                    font-size: 1.3rem;
                }
            }

            &.current {
                span {
                    color: $anchor-color-hover;
                }
            }
        }
    }

    .newsletter {
        //float: right;
        //max-width: 410px;

        >.title {
            font-family: $f-Neucha;
            font-size: 1.2rem;
            padding-bottom: 0.5rem;
            color: $fg-blue3;
            text-align: center;
        }

        .input-group-label {
            border-radius: 20px 0 0 20px;
            border-right: 0;
            border-color: $fg-input-border;
            background: white;
            padding-right: 0.5rem;
            color: $fg-icon-green;
        }
        .control {
            input {             
                color: $fg-icon-def;
                &:placeholder {
                    color: $fg-vio;
                }
            }
        }
        .action.subscribe {
            border: 1px solid $fg-input-border;
            border-radius: 0 20px 20px 0;
            border-left: 0;
            background: white;
            color: $fg-icon-green;
        }
    }

    // счетчики
    .FooterCounters {
        margin: 1.5rem 0;

        display: flex;
        justify-content: center;

        &-Items {
            &_subscribe {
                width: 100%;
            }
        }

        &-Item {
            padding: 0.5rem 0;
        }
        &-Item_vk {
            padding-top: 0;
        }

        &-Item_rambler {
            padding-top: 0.3rem;

            > a {
                padding: 0 1rem;
            }
        }
    }

    .VK {
        position: relative;
        //max-width: 415px;

        &-Icon {
            //position: absolute;
            //left: 0;
            //top: -0.3rem;
            padding: 0;

            display: inline-block;   
            text-align: center;

            font-size: 1.2rem;
            height: 2rem;
            width: 2rem;   
            line-height: 2rem;
            color: white;
            background: #5181b8;
            border-radius: 0.4rem;

            transition: $trans-bg-color;
            &:hover {
                background: lighten(#5181b8, 6);
            }
        }
        &-Widget {
            margin-left: 0.3rem;
        }
    }


    .BottomLine {
        // контейнер копирайта
        margin-top: 1rem;
    }

    // copyright
    .copyright {
        display: block;
        text-align: center;

        font-family: $f-Gabriela;
        font-size: 0.8rem;
        color: $fg-vio-dark2;
    }


    // Флекс грид в элементах футера
    .FooterGrid {
        display: flex !important;
        align-items: center;

        &-Cell {
            padding: 0 0.2rem;

            &_vk {
                width: 100%;
            }
        }
    }

}

// 2020-11-26
// ------------ Кнопки после футера ---------
// запреты вывода живосайта в мобильной верстке
body > jdiv {
    @include breakpoint (small down) {
        display: none;
    }
}

// кнопки мессенджеров
/* Прокрутка страницы  */


.MessengerButton { 
    $icon-size: 70px;

    display: block;
    //background: url("/.assets/42367aaa/arrows.png") 0 0 no-repeat;
    background: red;
    border-radius: 100%;
    overflow: hidden;
    width: $icon-size; height: $icon-size;

    &:not(:last-child) {
        margin-bottom: 10px;
    }

    opacity: 0.5;

    &-Container {
        width:$icon-size; height: $icon-size * 2 + 10px; 
        position:fixed;
        bottom: 1rem; 
        /*left: calc(50% - 25px); по центру */
        right: 1rem;
        z-index:3000; 
    }

    &_whatsapp {
        background: url("/media/images/_design/messenger-2020/whatsapp-100.png") 0 0 no-repeat;
        background-size: $icon-size $icon-size;
    }
    &_viber {
        background: url("/media/images/_design/messenger-2020/viber-100.png") 0 0 no-repeat;
        background-size: $icon-size $icon-size;
    }
}
.MessengerButton:hover {
    //transition: opacity, 0.3s, ease-in-out, 0.3s;
    //opacity: 1 !important;
}




// Вы вышли из системы
.customer-account-logoutsuccess {
    .main-column {
        >p {
            @include field-note;
            text-align: center;
        }
    }
}


// Войти в систему
.login-container {}

// Форма логина
.block-customer-login {
    .fieldset.login {
        margin-top: 0.4rem;
    }

    .action.login {
        margin-bottom: 0;
    }

    .action.remind {
        font-family: $f-Marmelad;
        text-decoration: underline;
        font-size: 0.9rem;

        margin-left: 1rem;
        vertical-align: baseline;
    }
}
// Новый клиент
.block-new-customer {    
    .block-content {
        margin-top: 0.5rem;
    }
}

// Забыл пароль
.block-password-forget {    
    margin-bottom: 1rem;
}

// Новая учетная запись
.block-create-account {
    .fieldset.create.info {}
    .fieldset.create.account {}

    // Скрываем силу пароля
    .password-strength-meter {
        display: none;
    }
}

// Сбросить пароль 
.form.password.reset,
.form.form-edit-account {
    // Скрываем силу пароля
    .password-strength-meter {
        display: none;
    }
}

// Подтверждение регистрации
.form.send.confirmation {
    .actions-toolbar {
        text-align: center;
    }
}



// ========= Панель управления =========================

// Дашборд
.block-dashboard-orders {
    // Заголовок
    .block-title {
        @include field-note;
        margin-top: 0.5rem;
        strong {
            font-weight: normal;
            font-size: 1.2rem;
        }
        .action.view {
            font-size: 0.9rem;
            margin-left: 1rem;
        }
    }   
    .table-caption {
        display: none;
    }
    .block-content {
        margin-top: 0.5rem;
    }
}

// Данные учетной записи  +++++++++++++++++++
.customer-account-edit {
    .field.choice:last-child {
        margin-bottom: 0;
        input {
            margin-bottom: 0;
        }
    }
    .main-column {
        .actions-toolbar {
            // text-align: center;
            .action.back {
                display: none;
            }
        }
    }
}

// Адресная книга  ++++++++++++++++++++++++++++
.customer-address-index {   
    .main-column {
        .actions-toolbar {
            @include form-actions-toolbar;
            margin-top: 1.0rem;
            text-align: center;

            .action.back {
                display: none;
            }
        }

        // Подзаголовки
        .block-title.content-header {
            font-size: 1.2rem;
        }


    }
}

// Текстовый сниппет даборда

.block-addresses-default,
.block-addresses-list,
.block-order-details-view,  // просмотр заказа
.block-dashboard-info,  // дашброд - данные учетные записи
.block-dashboard-addresses, // дашборд - адреса
.block-reviews-dashboard, // Последние отзывы
.block-order-details-comments, // Комментарии к заказу

{
    @include order-font;

    .block-content {
        >.empty {
            text-align: center;
        }
    }


    .box-title, .block-title {
        @include order-title;
    }
    .box-content, .block-content {
        @include order-content;
    }

    .box-actions, .item.actions {
        @include text-actions-toolbar;
    }

}

.block-addresses-list {
    .items.addresses {
        li.item {
            border-bottom: 1px solid $fg-blue-pastel;
            margin-bottom: 1rem;
        }
    }
}


// Форма редактирования адреса +++++++++++++++++++

.customer-address-form {
    .field.street {
        #street_1 {
            border-radius: $input-radius $input-radius 0 0;
        }
        #street_2 {
            border-top: 0;
            border-radius: 0 0 $input-radius $input-radius;
        }
    }

    .actions-toolbar {
        @include form-actions-toolbar;
        margin-top: 1.0rem;
        text-align: center;
    }
}

// Мои заказы +++++++++++++++++++++

.sales-order-history {
    .table-order-items {
        .table-caption {
            display: none;
        }
    }

    .main-column {
        .actions-toolbar {
            @include form-actions-toolbar;
            margin-top: 1.0rem;
            text-align: center;

            .action.back {
                display: none;
            }
        }

        // Тулбар пейджера
        .order-products-toolbar {
            .pager {
                width: 100%;
                .limiter {
                    float: right;
                }
                .pages {
                    margin-left: 1rem;
                }
            }
        }
    }
}

.table-order-items,
.table-reviews, // мои отзывы о товарах
{
    @include order-font;
    margin-bottom: 1rem;

    .table-caption {
        display: none;
    }
    .col.total {
        text-align: right;
    }
    .action {
        @include icon-edit;
        text-decoration: underline;
        margin-right: 0.5rem;
    }
}

.order-products-toolbar,
.products-reviews-toolbar, // мои отзывы о товарах
{
    .pager {
        @include inline;
    }
}

// Просмотр заказа ++++++++++++++++++++++
.sales-order-view, .sales-order-print, .sales-guest-view {
    // Прячем лишние закладки в табах
    // Кнопки табов
    .items.order-links {
        display: none;
    }
    // Контент табов
    .order-details-items {
        .order-title, .table-caption {
            display: none;
        }
        // Таблица
        .order-items {
            margin-top: 0.5rem;
        }
        // Столбцы
        .col {
            &.price, &.qty, &.subtotal {
                text-align: right;
            }
            // Количество
            &.qty {
                ul {
                    list-style-type: none;
                    margin: 0;
                }
                .title {display: none;}
            }
        }
        // Итоги
        tfoot {
            .mark, .amount {
                text-align: right;
            }
        }

        .actions-toolbar {
            @include form-actions-toolbar;
            margin-top: 1.0rem;
            text-align: center;
        }

        //Заказанные позиции
        &.ordered {
        }
    }
}

.order-actions-toolbar {
    @include text-actions-toolbar;
}
// Информация о заказе
.block-order-details-view {
    margin-bottom: 1rem;
    .block-title {
        @include content-header;
        strong {
            font-weight: normal;
        }
    }
    .box-order-billing-address,
    .box-order-billing-method
    {
        display: none;
    }
}

// Подписка на новости ++++++++++++++++++++++++
.newsletter-manage-index {
    .main-column {
        .actions-toolbar {
            .action.back {
                display: none;
            }
        }
    }
}

.form-newsletter-manage-wrapper {
}

// Мои отзывы ++++++++++++++++++++++++++++++++++++
.review-customer-index {
    .main-column {
        .actions-toolbar {
            .action.back {
                display: none;
            }
        }
    }
}

.table-reviews {
    .col {
        &.summary {
            text-align: center;
            @include reviews-summary;
        }
    }
}

// Просмотр отзыва +++++++++++++++++++++++++++++++++++++
.review-customer-view {
    .actions-toolbar {
        margin-bottom: 1.0rem;
        text-align: center;
    }
}

// Контейнер отзыва
.customer-review {
    // Картинка
    .product-media {}
    // Инфо
    .product-info {
        @include product-reviews-summary (24px, auto);
        .product-reviews-summary {
            text-align: left;
            &.empty {}

            @include product-reviews-actions;
            .reviews-actions {
            }
        }
    }
}


// Зона моих желаний
.wishlist-index-index {
    .main-column {

        .actions-toolbar {
            @include form-actions-toolbar;
            margin-top: 0.5rem;
            text-align: center;

            margin-bottom: 0.5rem;

            .primary .action {
                margin-bottom: 0.5rem;
            }

            .action.back {
                display: none;
            }
        }
    }
}

.products-grid.wishlist {
    .product-item-info {
        // Название
        .product-item-name {
            font-weight: normal;

            // Фиксированная высота
            display: block;
            height: 55px;
            margin-bottom: 5px;
            overflow: hidden;
        }


        // Блок с кнопкой добавить в корзину и удалить
        .product-item-details .product-item-inner {
            .actions-secondary {
                >* {
                    display: inline-block;
                    vertical-align: middle;
                }
                // Комментарии
                .comment-box {
                    display: none;
                }

                .product-item-actions {
                    height: auto;

                    .actions-primary, .actions-secondary {
                        margin: 0;
                    }
                }
            }
        }


        // Количество и добавить в корзину
        .box-tocart {
            .fieldset {
                border: 0; padding: 0;
                @include inline;
            }
            // Кол-во
            .field.qty {
                min-width: 70px;
                text-align: left;
                margin-bottom: 0;

                .label{
                    font-size: 0.75rem;
                    display: none;
                }
                .control {
                    float: right;
                    margin-right: 1rem;
                }

                input {                                  
                    @include product-qty-input;
                }
            }
            // Доступность
            .stock {
                @include product-item-stock;
            }
        }
        // Кнопки добавить, удалить
        .actions-secondary {
            .action {
                font-size: 1.2rem; 
            }
            .edit {
                @include icon-edit;
            }
            .delete {
                @include icon-remove;
            }
        }
    }
}


// ++++++++++++++ Дашборд - низ +++++++++++++++++++++++
.customer-account-index {}

// Данные учетной записи
.block-dashboard-info {
    margin-bottom: 1.5rem;
}
// Адреса
.block-dashboard-addresses, .block-reviews-dashboard {
    margin-bottom: 1.5rem;
    .block-title {
        .edit, .view {
            font-weight: normal;
            font-size: 0.9rem;
            margin-left: 1rem;
        }
    }
}

// Последние отзывы
.block-reviews-dashboard {
    .item {

        @include reviews-summary (20px, auto);
        .rating-summary {
            margin-left: 1rem;
            margin-top: -2px;
            display: inline-block;
            vertical-align: middle;
        }
    }
}


// Заказы и возвраты - гостевой - в футере +++++++++++++++++++++++++++++++++++

.sales-guest-form {}

.form-orders-search-wrapper {}

// Просмотр заказа - гостевой
.sales-guest-view {
}


// Комментарии к заказу
.block-order-details-comments {
    .block-title {
        color: transparent;
    }
    
    .comment-date {
        font-size: 1rem;
    }
}


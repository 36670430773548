
// Печать 

body.page-print {
    // Общее
    a.logo, .copyright {
        display: block;
        text-align: center;
    }
    footer, a.logo {
        display: none;
    }


    // Печать заказа
    &.sales-order-print {
        .actions-toolbar {
            display: none;
        }
    }

}